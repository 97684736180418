import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { Container, Row, Col, Form, Button, ProgressBar, Alert, Table } from 'react-bootstrap';

const InsihgtAIManualPipeline = ({ title, userData }) => {
  const [responseStepFunction, setResponseStepFunction] = useState(null);
  const [responseApproveInsihgtAIExecution, setResponseApproveInsihgtAIExecution] = useState(null);
  const [error, setError] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState('Copy to Clipboard');
  const [downloadButtonText, setDownloadButtonText] = useState('Download JSON');
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [getExecutionsButtonText, setGetExecutionsButtonText] = useState('Generate Step Function Execution');
  const [approveButtonText, setApproveButtonText] = useState({});
  const [generatedSitemap, setGeneratedSitemap] = useState(null);
  const fileInputRef = useRef(null);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const LAMBDA_GENERATE_SITEMAP_URL = 'https://dwgczt6rqwoezutxv4bvj2thsy0yoote.lambda-url.eu-west-1.on.aws/';
  const LAMBDA_UPLOAD_AMAZON_REVIEWS_URL_TO_S3 = 'https://btdpht4gnjl5gmivgyzodhno3e0rzbpt.lambda-url.eu-west-1.on.aws/';
  const LAMBDA_GET_STEP_FUNCTION_EXECUTIONS = 'https://6b5jeaiutlhwten7w6kywfh76y0akmsu.lambda-url.eu-west-1.on.aws/';
  const LAMBDA_APPROVE_InsihgtAI_EXECUTION = 'https://lfsmpgluex57qsckcuv76ejgja0yoekx.lambda-url.eu-west-1.on.aws/';
  const FILE_CHUNK_SIZE = 4 * 1024 * 1024; // 6MB is lambda function limit so we must split the file into chunks

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      interval = setInterval(() => {
        handleSubmitGetExecutions();
      }, 10000); // table will refresh every 10 seconds
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  const handleGenerateSitemap = async (taskId) => {
    try {
      const response = await axios.post(LAMBDA_GENERATE_SITEMAP_URL, { task_id: taskId });
      setGeneratedSitemap(response.data);
    } catch (err) {
      setError('Failed to generate sitemap: ' + err.message);
    }
  };

  const handleSubmitGetExecutions = async () => {
    try {
      const responseStepFunction = await axios.get(LAMBDA_GET_STEP_FUNCTION_EXECUTIONS);
      setResponseStepFunction(responseStepFunction.data);
      setGetExecutionsButtonText('Regenerate Step Function Execution');
      setAutoRefresh(true);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handleCopyToClipboard = async (data) => {
    try {
      const jsonString = JSON.stringify(data, null, 2);
      await navigator.clipboard.writeText(jsonString);
      setCopyButtonText('Copied');
      setTimeout(() => setCopyButtonText('Copy to Clipboard'), 2000);
    } catch (err) {
      setError('Failed to copy to clipboard: ' + err.message);
    }
  };

  const handleDownloadJson = (data, filename) => {
    try {
      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDownloadButtonText('Downloaded');
      setTimeout(() => setDownloadButtonText('Download JSON'), 2000);
    } catch (err) {
      setError('Failed to download file: ' + err.message);
    }
  };

  const resetUpload = () => {
    setSelectedFile(null);
    setUploadProgress({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setUploadProgress({});
    }
  };

  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        resolve(reader.result);
        reader.onerror = null;
        reader.onload = null;
      };
      
      reader.onerror = () => {
        reject(reader.error);
        reader.onerror = null;
        reader.onload = null;
      };

      reader.readAsText(file);
    });
  };

  const splitIntoChunks = (content, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < content.length; i += chunkSize) {
      chunks.push(content.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleFileUpload = async (executionArn) => {
    if (!selectedFile) {
      setUploadError('Please select a file first');
      return;
    }
  
    setUploading(true);
    setUploadError(null);
    setUploadSuccessMessage(null);
    setUploadProgress((prev) => ({ ...prev, [executionArn]: 0 }));
  
    try {
      const fileContent = await readFileAsText(selectedFile);
      const uploadId = `upload_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      
      // Initialize upload
      await axios.post(LAMBDA_UPLOAD_AMAZON_REVIEWS_URL_TO_S3, {
        action: 'init',
        file_name: selectedFile.name,
        upload_id: uploadId,
        total_size: fileContent.length,
      });
  
      const chunks = splitIntoChunks(fileContent, FILE_CHUNK_SIZE);
      const totalChunks = chunks.length;
  
      // Upload chunks
      for (let i = 0; i < chunks.length; i++) {
        await axios.post(LAMBDA_UPLOAD_AMAZON_REVIEWS_URL_TO_S3, {
          action: 'upload_chunk',
          file_name: selectedFile.name,
          chunk_index: i,
          total_chunks: totalChunks,
          upload_id: uploadId,
          chunk_content: chunks[i],
        });
  
        setUploadProgress((prev) => ({ ...prev, [executionArn]: ((i + 1) / totalChunks) * 100 }));
      }
  
      // Finalize upload
      const response = await axios.post(LAMBDA_UPLOAD_AMAZON_REVIEWS_URL_TO_S3, {
        action: 'finalize',
        file_name: selectedFile.name,
        upload_id: uploadId,
      });
  
      setUploadSuccessMessage(`File ${selectedFile.name} upload successful!`);
      resetUpload();
    } catch (err) {
      setUploadError(`Upload failed: ${err.message}`);
    } finally {
      setUploading(false);
    }
  };
  
  const handleApproveInsihgtAIExecutionButton = async (executionArn) => {
    setError(null);
    setResponseApproveInsihgtAIExecution(null);
    setApproveButtonText((prev) => ({ ...prev, [executionArn]: 'Approving...' }));

    try {
      const payload = {
        payload: {
          execution_arn: executionArn
        }
      };

      const response = await axios.post(LAMBDA_APPROVE_InsihgtAI_EXECUTION, payload);
      setResponseApproveInsihgtAIExecution(response.data.message);
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'An unknown error occurred';
      setError(`Error: ${errorMessage}`);
      console.error('Error:', err);
    } finally {
      setApproveButtonText((prev) => ({ ...prev, [executionArn]: 'Processing' }));
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      resetUpload();
    };
  }, []);

  return (
    <>
      <Sidebar userData={userData} />
      {userData.is_insightai ? (
        <Container fluid className="App with-sidebar bg-light-pf23 rounded-bottom min-vh-100">
          <Navbar userData={userData} goBackButton={true} loggingButtons={true} />
          <section className="p-4">
            <Row className='justify-content-center mb-4'>
              <Col xs={12} md={6} xl={4}>
                <Button 
                  variant='pf23'
                  onClick={handleSubmitGetExecutions}
                >
                  {getExecutionsButtonText}
                </Button>
              </Col>
            </Row>
            {responseStepFunction && (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Execution ARN</th>
                    <th>Stage</th>
                    <th>Start Date</th>
                    <th>Task ID</th>
                    <th>Spider ID</th>
                    <th>Project ID</th>
                    <th>URL</th>
                    <th style={{ 'width': '12%' }}>Generate Sitemap</th>
                    <th style={{ 'width': '17%' }}>Upload Reviews</th>
                    <th>Approve Request</th>
                  </tr>
                </thead>
                <tbody>
                  {responseStepFunction.executions.length > 0 ? (
                    responseStepFunction.executions.map((execution, index) => {
                      // Format the startDate
                      const startDate = new Date(execution.startDate);
                      const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')} ${String(startDate.getHours()).padStart(2, '0')}:${String(startDate.getMinutes()).padStart(2, '0')}`;

                      const isWaitForApproval = execution.currentState === 'WaitForApproval';
                      const isSubmitScrapfinderlabProduction = execution.currentState === 'submit_scrapfinderlab_production_insightai_manual_job';

                      return (
                        <tr key={index}>
                          <td><a href={execution.step_function_url} target='_blank'>
                            {execution.executionArn.split(':').pop()}</a></td>
                          <td>{execution.currentState}</td>
                          <td>{formattedStartDate}</td>
                          <td>{execution.input.task_id}</td>
                          <td>{execution.input.spider_id}</td>
                          <td>{execution.input.project_id}</td>
                          <td><a href={execution.input.url} target='_blank'>link</a></td>
                          <td>
                            <Button 
                              variant='pf23'
                              className="m-1 p-2"
                              onClick={() => handleGenerateSitemap(execution.input.task_id)}
                              disabled={!isWaitForApproval}
                            >
                              Generate Sitemap
                            </Button>
                            {isSubmitScrapfinderlabProduction && <p>Data scraping in progress...</p>}
                            {generatedSitemap && (
                              <>
                                <Button 
                                  variant='pf23'
                                  className="m-1 p-2"
                                  onClick={() => handleCopyToClipboard(generatedSitemap)}
                                  disabled={!isWaitForApproval}
                                >
                                  {copyButtonText}
                                </Button>
                                <Button 
                                  variant='pf23'
                                  className="m-1 p-2"
                                  onClick={() => handleDownloadJson(generatedSitemap, 'sitemap.json')}
                                  disabled={!isWaitForApproval}
                                >
                                  {downloadButtonText}
                                </Button>
                              </>
                            )}
                          </td>
                          <td>
                            <Form.Group className="mb-2">
                              <Form.Control
                                ref={fileInputRef}
                                type="file"
                                onChange={handleFileChange}
                                accept=".xlsx, .csv"
                                disabled={uploading || !isWaitForApproval}
                                key={selectedFile ? undefined : 'file-input'}
                              />
                            </Form.Group>
                            {isSubmitScrapfinderlabProduction && <p>Data scraping in progress...</p>}
                            {selectedFile && (
                              <Button 
                                variant='pf23'
                                className='m-1 p-2'
                                onClick={() => handleFileUpload(execution.executionArn)}
                                disabled={uploading || !isWaitForApproval}
                              >
                                {uploading ? 'Uploading...' : 'Upload'}
                              </Button>
                            )}
                            {uploading && uploadProgress[execution.executionArn] !== undefined && (
                              <div className="mt-2">
                                <ProgressBar 
                                  now={uploadProgress[execution.executionArn]} 
                                  label={`${Math.round(uploadProgress[execution.executionArn])}%`} 
                                  animated
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <Button 
                              variant='pf23'
                              className='m-1 p-2'
                              onClick={() => handleApproveInsihgtAIExecutionButton(execution.executionArn)}
                              disabled={!isWaitForApproval}
                            >
                              {approveButtonText[execution.executionArn] || 'Approve'}
                            </Button>
                            {isSubmitScrapfinderlabProduction && <p>Data scraping in progress...</p>}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    // Display a message if there are no executions
                    <tr>
                      <td colSpan="10" className="text-center">
                        No active requests
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
            {error && (
              <Alert
                variant="danger"
                onClose={() => setError(null)}
                dismissible
              >
                <Alert.Heading>Error</Alert.Heading>
                <p>{error}</p>
              </Alert>
            )}
            {uploadError && (
              <Alert
                variant="danger"
                onClose={() => setUploadError(null)}
                dismissible
              >
                <Alert.Heading>Error</Alert.Heading>
                <p>{uploadError}</p>
              </Alert>
            )}
            {uploadSuccessMessage && (
              <Alert
                variant="success"
                onClose={() => setUploadSuccessMessage(null)}
                dismissible
              >
                <Alert.Heading>Success</Alert.Heading>
                <p>{uploadSuccessMessage}</p>
              </Alert>
            )}
            {responseApproveInsihgtAIExecution && (
              <Alert
                variant="success"
                onClose={() => setResponseApproveInsihgtAIExecution(null)}
                dismissible
              >
                <Alert.Heading>Success</Alert.Heading>
                <p>{responseApproveInsihgtAIExecution}</p>
              </Alert>
            )}
          </section>
        </Container>
      ) : (
        <Container fluid className="App with-sidebar bg-light-pf23 rounded-bottom min-vh-100">
          <Navbar userData={userData} goBackButton={true} loggingButtons={true} />
          <section className="p-4">
            <Row className='justify-content-center mb-2'>
              <Col xs={12} md={9} xl={7}>
                <h1>Page requires InsightAI-level access.</h1>
                <h3>If you are supposed to access it, please contact our team.</h3>
              </Col>
            </Row>
          </section>
        </Container>
      )}
    </> 
  );
};

export default InsihgtAIManualPipeline;