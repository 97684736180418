// Chain spiders page
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { FaSyncAlt } from "react-icons/fa";

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import ManageSchedulesModal from '../components/modals/ManageSchedulesModal';
import ChainedSpidersTable from '../components/tables/ChainedSpidersTable';
import SpiderContext from '../components/contexts/SpiderContext';


import config from '../config';
import DjangoAPIClient from '../client';

const client = new DjangoAPIClient(config);

const ChainedSpiders = ({ title, userData }) => {
    const navigate = useNavigate();
    const [spiderContext, setSpiderContext] = useContext(SpiderContext);
    const [refreshData, setRefreshData] = useState(0);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ statusText: "" });
    const [showSchedulesModal, setShowSchedulesModal] = useState(false);
    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <ManageSchedulesModal
                    client={client}
                    userData={userData}
                    showModal={showSchedulesModal}
                    setShowModal={setShowSchedulesModal}
                    spider={spiderContext}
                    error={error}
                    spider_type="chained"
                    spiderPipeline={spiderContext.steps}
                />
                <section>
                    <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                        <Col sm={12} md={6} className='header'>
                            <header className="bg-white pt-2">
                                <h1 className='text-start'>Chained spiders</h1>
                            </header>
                        </Col>
                        <Col sm={12} md={6} className='d-flex justify-content-md-end justify-content-sm-start'>
                            <Button
                                className='rounded-pill'
                                variant='pf23'
                                onClick={(e) => navigate("/create-chained-spider")}
                                disabled={error}
                            >
                                Create Chained Spider
                            </Button>
                        </Col>
                    </Row>

                    <Row className="px-3 py-2">
                        <Col sm={8} md={3}>
                        </Col>
                        <Col sm={4} md={9} className="refresh">
                            <Button
                                type="button"
                                variant="dark"
                                className="rounded-circle"
                                onClick={(e) => {
                                    setRefreshData(refreshData + 1);
                                }}
                            >
                                <FaSyncAlt />
                            </Button>
                        </Col>
                    </Row>

                    <Row className='px-3 py-2'>
                        <ChainedSpidersTable
                            client={client}
                            userId={userData.id}
                            setSpider={setSpiderContext}
                            setShowSchedulesModal={setShowSchedulesModal}
                            maxPagination={10}
                            refreshData={refreshData}
                            setRefreshData={setRefreshData}
                            setShowAlert={setShowAlert}
                            setAlertMessage={setAlertMessage}
                            setError={setError}
                        />
                    </Row>
                </section>
            </Container>
        </>
    );
};

export default ChainedSpiders;