import React from "react";
import { useState, useEffect } from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ItemsDropdown from "../ItemsDropdown";
import InputTextEdit from "../InputTextEdit";

import Delayed from "../DelayedComponent";

// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.possibleSpiderKwargs -> list of possible kwargs
// props.spiderKwargs, props.setSpiderKwargs -> spider kwargs (str)


const SpiderKwargsModal = (props) => {
	const [error, setError] = useState("")

	const [selectedSpiderKwargs, setSelectedSpiderKwargs] = useState({}); // Possible kwargs with values if provided
	const [customSpiderKwargs, setCustomSpiderKwargs] = useState("{}"); // Rest of the kwargs that are not in the possible kwargs

	const [newSpiderKwargs, setNewSpiderKwargs] = useState("{}"); // String representing updated spider kwargs

	useEffect(() => {
		processCurrentSpiderKwargs();
	}, [props.showModal, props.possibleSpiderKwargs, props.spiderKwargs]);

	function processCurrentSpiderKwargs() {
		const spiderKwargsObject = JSON.parse(props.spiderKwargs); // Convert current spider kwargs to object

		if (Object.keys(props.possibleSpiderKwargs).length > 0) {

			// Adding values to the possible kwargs if they are present in the current spider kwargs
			const selectedSpiderKwargsWithValues = props.possibleSpiderKwargs.map(kwargs => {
				if (kwargs.type == "multiple_choice_list") {
					if (spiderKwargsObject.hasOwnProperty(kwargs.name)) {
						return { ...kwargs, value: JSON.parse(spiderKwargsObject[kwargs.name])};
					} else {
						return { ...kwargs, value: [] }; // Add an empty array if the key is not found
					}
				} else {
					if (spiderKwargsObject.hasOwnProperty(kwargs.name)) {
						return { ...kwargs, value: spiderKwargsObject[kwargs.name] };
					} else {
						return { ...kwargs, value: "" }; // Add an empty value if the key is not found
					}
				}
			});
			setSelectedSpiderKwargs(selectedSpiderKwargsWithValues);

			// Filtering spider kwargs so it leaves only the ones that are not in the possible kwargs
			const restOfKwargs = Object.keys(spiderKwargsObject)
				.filter(key => !props.possibleSpiderKwargs.some(kwargs => kwargs.name === key))
				.map(key => ({ "name": key, "value": spiderKwargsObject[key] }));

			const convertArrayToObject = (array) => {
				const result = {};
				array.forEach(item => {
					result[item.name] = item.value;
				});
				return result;
			};
			setCustomSpiderKwargs(JSON.stringify(convertArrayToObject(restOfKwargs)));
		} else {
			if (Object.keys(spiderKwargsObject).length > 0) {
				setCustomSpiderKwargs(JSON.stringify(spiderKwargsObject));
			}
		}
	}

	useEffect(() => {
		// console.log(customSpiderKwargs);
		// console.log(selectedSpiderKwargs)
	}, [customSpiderKwargs, selectedSpiderKwargs]);

	function updateSpiderKwargs() {
		// Step 1: Convert the spiderKwargs string back to an object
		let customSpiderKwargsObject = JSON.parse(customSpiderKwargs);
		let selectedSpiderKwargsObject = selectedSpiderKwargs;

		// Steps 2-4: Iterate over selectedSpiderKwargs and update/add values
		if (Object.keys(selectedSpiderKwargsObject).length > 0) {
			selectedSpiderKwargsObject.forEach(option => {
				if (option.value !== "") { // Check if the value is not empty
					customSpiderKwargsObject[option.name] = option.value;
				}
			});
		}
		// Step 5: Convert the updated object back to a string
		if (Object.keys(customSpiderKwargsObject).length === 0) {
			setNewSpiderKwargs("{}");
		} else {
			setNewSpiderKwargs(JSON.stringify(customSpiderKwargsObject));
		}
	}

	function canBeParsedToObject(str) {
		try {
			JSON.parse(str);
		  return true; // Parsing was successful
		} catch (e) {
		  return false; // Parsing failed
		}
	}

	function closeModal() {
		setError("");
		setCustomSpiderKwargs("{}");
		setSelectedSpiderKwargs({});
		props.setShowModal(false);
	}

	function saveCloseModal() {
		if (!canBeParsedToObject(customSpiderKwargs)) {
			setError("Invalid JSON format");
			return;
		}
		updateSpiderKwargs();
		closeModal();
	}

	useEffect(() => {
		props.setSpiderKwargs(newSpiderKwargs);
	}, [newSpiderKwargs])	

	const CustomDropdownWithCheckboxes = ({ options, selectedOptions, setSelectedOptions }) => {
		const handleCheckboxChange = (e, optionValue) => {
			e.preventDefault(); // Prevent default to stop checkbox from toggling automatically
			e.stopPropagation(); // Stop propagation to keep the dropdown open
	
			const isSelected = selectedOptions.includes(optionValue);
			const newSelectedOptions = isSelected
				? selectedOptions.filter((opt) => opt !== optionValue)
				: [...selectedOptions, optionValue];
	
			setSelectedOptions(newSelectedOptions);
		};
	
		// Prevent dropdown from closing when clicking on the dropdown menu itself
		const handleMenuClick = (e) => {
			e.stopPropagation();
		};
	
		// Display selected options separated by commas or show "No options selected" if none are selected
		const displaySelectedOptions = Array.isArray(selectedOptions) && selectedOptions.length > 0
				? selectedOptions.join(', ')
				: "No options selected";
	
		return (
			<Dropdown id="project-dropdown" onClick={handleMenuClick}>
				<Dropdown.Toggle
					variant='dark'
					className="d-flex align-items-center justify-content-between rounded-pill"
				>
					{displaySelectedOptions}
				</Dropdown.Toggle>
	
				<Dropdown.Menu
					variant="dark"
					className='w-100 overflow-auto px-3'
					style={{ maxHeight: '300px' }}
				>
					{options.map((opt, index) => (
						<Form.Check
							key={index}
							type="checkbox"
							id={`dropdown-checkbox-${index}`}
							label={opt}
							checked={selectedOptions.includes(opt)}
							onChange={(e) => e.preventDefault()} // Prevent onChange from toggling the checkbox
							onClick={(e) => handleCheckboxChange(e, opt)} // Use onClick to manually toggle
							custom
						/>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const CustomDropdownWithSingleSelection = ({ items, selectedValue, setSelectedValue }) => {
		const handleChange = (eventKey) => {
			setSelectedValue(eventKey); // Assuming setSelectedValue updates the state
		};

		const options = items.map(item => ({
			key: item.value,
			text: item.label,
			value: item.value,
		}));

		options.unshift({ key: 'select', text: 'Select', value: '' });
		return (
			<Dropdown id="project-dropdown" onSelect={handleChange}>
				<Dropdown.Toggle
					variant='dark'
					className="d-flex align-items-center justify-content-between rounded-pill"
				>
					{selectedValue ? selectedValue : "Select"}
				</Dropdown.Toggle>

				<Dropdown.Menu
					variant="dark"
					className='w-100 overflow-auto'
					style={{ maxHeight: '300px' }}
				>
					{options.map((opt, index) => (
						<Dropdown.Item key={opt.key} eventKey={opt.value}>
							{opt.text}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	return (
		<Modal size="lg" show={props.showModal} onHide={(e) => closeModal()}>
			<Modal.Header closeButton>
				<Modal.Title>Edit spider kwargs</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Delayed waitBeforeShow="800">
					<Form>
						<Form.Group controlId="projectName" className="scrollableForm">
							{Object.keys(selectedSpiderKwargs).length > 0 && selectedSpiderKwargs.map((option, index) => (
								<Row className="pb-2 px-2" key={index}>
									<Form.Group>
										<Form.Label>{option.name}</Form.Label><span> </span>
										<Form.Text className="text-muted">
											{option.description}
										</Form.Text>
										{option.type === 'multi_choice_list' ? (
											<CustomDropdownWithCheckboxes
												options={option.options} // Your options array
												selectedOptions={selectedSpiderKwargs[index].value} // Current selected options
												setSelectedOptions={(newSelectedOptions) => {
													const updatedOptions = [...selectedSpiderKwargs];
													updatedOptions[index].value = newSelectedOptions;
													setSelectedSpiderKwargs(updatedOptions);
												}}
											/>
										) : option.type === 'single_choice_list' ? (
											<CustomDropdownWithSingleSelection
												items={option.options.map((val, valIndex) => ({ value: val, label: val }))}
												placeholder="Select"
												selectedValue={option.value}
												setSelectedValue={(newValue) => {
													const updatedOptions = [...selectedSpiderKwargs];
													updatedOptions[index].value = newValue;
													setSelectedSpiderKwargs(updatedOptions);
												}}
											/>
											// ) : option.type === 'bool' ? (
											// 	<Form.Control className="rounded-pill" as="select" value={option.value} onChange={(e) => {
											// 		const updatedOptions = [...selectedSpiderKwargs];
											// 		updatedOptions[index].value = e.target.value;
											// 		setSelectedSpiderKwargs(updatedOptions);
											// 	}}>
											// 		<option value=""></option>
											// 		<option value="true">True</option>
											// 		<option value="false">False</option>
											// 	</Form.Control>
										) : (
											<Form.Control
												type={option.type === 'int' || option.type === 'float' ? 'number' : 'text'}
												className="rounded-pill"
												placeholder={option.value}
												value={option.value}
												onChange={(e) => {
													const updatedOptions = [...selectedSpiderKwargs];
													updatedOptions[index].value = e.target.value;
													setSelectedSpiderKwargs(updatedOptions);
												}}
											/>
										)}
									</Form.Group>
								</Row>
							))}
							<Form.Group className="pb-2 px-2">
								<Form.Label>additional kwargs</Form.Label> <span> </span>
								<Form.Text className="text-muted">kwargs not defined in spider-configs</Form.Text>
								<Form.Control
									type='text'
									className="rounded-pill"
									placeholder={customSpiderKwargs}
									value={customSpiderKwargs}
									onChange={(e) => { setCustomSpiderKwargs(e.target.value) }}
								/>
								{error && <Form.Text className="text-danger">{error}</Form.Text>}
								{/* <InputTextEdit
									currentText={customSpiderKwargs}
									setCurrentText={setCustomSpiderKwargs}
									isInvalid={!!error}
								/> */}
							</Form.Group>
						</Form.Group>
					</Form>
				</Delayed>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="modal"
					className="rounded-pill"
					onClick={saveCloseModal}
				>
					Save
				</Button>
				<Button
					variant="dark"
					className="rounded-pill"
					onClick={closeModal}
				>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default SpiderKwargsModal;