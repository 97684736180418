import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import Sidebar from "../components/Sidebar";
import Navbar from '../components/Navbar';

import config from '../config';
import DjangoAPIClient from '../client';
import ItemsDropdown from '../components/ItemsDropdown';

const client = new DjangoAPIClient(config);

const CreateChainedSpider = ({ title, userData }) => {
    const [groupName, setGroupName] = useState('');
    const [steps, setSteps] = useState([]);
    const [availableSteps, setAvailableSteps] = useState([]);
    const [selectedSpider, setSelectedSpider] = useState({ id: '', name: 'Select spider' });
    const [pageType, setPageType] = useState('');
    const [currentStepEngine, setCurrentStepEngine] = useState('');
    const [isAddingStep, setIsAddingStep] = useState(false);
    const [editingStepIndex, setEditingStepIndex] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [hasInteractedWithName, setHasInteractedWithName] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = title;
        fetchSpiders();
    }, [title]);

    const fetchSpiders = async () => {
        try {
          const response = await client.getAllDropdownChainedSpiders();
          const spiders = response.data.map(spider => ({
            ...spider,
            engine: spider.is_webscraper ? 'webscraper' : 'scrapfinderlab'
          }));
          setAvailableSteps(spiders);
        } catch (error) {
          console.error('Error fetching available steps:', error);
        }
      };

    const validateForm = () => {
        const errors = [];

        // Check group name
        if (!groupName.trim()) {
            errors.push('Please enter a name for the chained spider.');
        }

        // Check steps
        if (steps.length === 0) {
            errors.push('Please add at least one step to the chained spider.');
        }

        setValidationErrors(errors);
        return errors.length === 0;
    };

    const handleNameChange = (e) => {
        setGroupName(e.target.value);
        if (!hasInteractedWithName) {
            setHasInteractedWithName(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (validateForm()) {
            try {
                const formattedSteps = steps.map((step, index) => ({
                    spider_id: step.id,
                    engine: step.engine,
                    page_type: step.page_type,
                    order: index + 1,
                    spider: step.name,
                    webscraper_account: step.webscraper_account
                }));
    
                const payload = {
                    name: groupName,
                    user: userData.id,
                    steps: formattedSteps
                };
    
                const response = await client.createNewChainedSpider(payload);
                navigate('/chained-spiders');
            } catch (error) {
                console.error('Error:', error);
                let errorMessages = [];
                
                if (error.response && error.response.data) {
                    const errorData = error.response.data.error;
                    if (typeof errorData === 'string') {
                        errorMessages = [errorData];
                    } else if (typeof errorData === 'object') {
                        errorMessages = Object.entries(errorData).flatMap(([key, value]) =>
                            Array.isArray(value) ? value.map(msg => `${key}: ${msg}`) : [`${key}: ${value}`]
                        );
                    }
                } else {
                    errorMessages = [error.message || 'An unexpected error occurred.'];
                }
                
                setValidationErrors(errorMessages);
            }
        }
    };

    const handleStepSelect = (step) => {
        const selectedStep = availableSteps.find(s => s.id === step.id);
        if (selectedStep) {
            setSelectedSpider(selectedStep);
            setPageType(selectedStep.page_type || '');
            setCurrentStepEngine(selectedStep.engine);
        }
    };

    const handleAddNewStep = () => {
        setIsAddingStep(true);
        setSelectedSpider({ id: '', name: 'Select spider' });
        setPageType('');
        setCurrentStepEngine('');
    };

    const handleAddStep = () => {
        if (selectedSpider.id) {
            setSteps([...steps, {
                id: selectedSpider.id,
                name: selectedSpider.name,
                page_type: pageType,
                engine: currentStepEngine,
                webscraper_account: selectedSpider.webscraper_account
            }]);
    
            // Reset to initial state
            setIsAddingStep(false);
            setSelectedSpider({ id: '', name: 'Select spider' });
            setPageType('');
            setCurrentStepEngine('');
        }
    };    

    const isStepAdditionValid = () => {
        return selectedSpider.id;
    };

    const handleEditStep = (index) => {
        setEditingStepIndex(index);
        const step = steps[index];
        setSelectedSpider({ id: step.id, name: step.name });
        setPageType(step.page_type);
        setCurrentStepEngine(step.engine);
        setIsAddingStep(true);
    };

    const handleUpdateStep = () => {
        const updatedSteps = steps.map((step, index) => {
            if (index === editingStepIndex) {
                return {
                    id: selectedSpider.id,
                    name: selectedSpider.name,
                    page_type: pageType,
                    engine: currentStepEngine,
                    webscraper_account: selectedSpider.webscraper_account
                };
            }
            return step;
        });

        setSteps(updatedSteps);
        setEditingStepIndex(null);
        setIsAddingStep(false);
        setSelectedSpider({ id: '', name: 'Select spider' });
        setPageType('');
        setCurrentStepEngine('');
    };

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                    <header className="bg-white pt-2">
                        <h1 className='text-start'>Create new chained spider</h1>
                    </header>
                </Row>
                <Row className='px-3 py-1'>
                    <Col xs={12} md={6} className='mx-auto'>
                        {/* Validation Errors */}
                        {validationErrors.length > 0 && (
                            <Alert variant="danger" className="mt-3">
                                {validationErrors.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </Alert>
                        )}
    
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="groupName">
                                <Form.Label><h3>Name</h3></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={groupName}
                                    onChange={handleNameChange}
                                    required
                                />
                                {hasInteractedWithName && !groupName.trim() && (
                                    <Alert variant="danger" className="mt-2">
                                        Name is required.
                                    </Alert>
                                )}
                                {!groupName.trim() && (
                                    <Form.Text className="text-muted">
                                        Please type spider chained name first.
                                    </Form.Text>
                                )}
                            </Form.Group>
    
                            {groupName.trim() && (
                                <Form.Group controlId="steps" className="mt-3">
                                    <h3>Steps</h3>
    
                                    {/* Display existing steps */}
                                    {steps.map((step, index) => (
                                        <div key={index} className="mb-3 p-3 border rounded">
                                            <Form.Label><h5>Step {index + 1}</h5></Form.Label>
                                            <div >
                                                {editingStepIndex === index ? (
                                                    <Button
                                                        variant="dark"
                                                        onClick={handleUpdateStep}
                                                        disabled={!isStepAdditionValid()}
                                                    >
                                                        Update Step
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="dark"
                                                        onClick={() => handleEditStep(index)}
                                                    >
                                                        Edit Step
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="dark"
                                                    onClick={() => window.open(`/spiders/${step.id}`, '_blank')}
                                                    className='mx-2'
                                                >
                                                    Go to Spider
                                                </Button>
                                            </div>
                                            <hr />
                                            <Form.Group>
                                                <Form.Label>Spider</Form.Label>
                                                {editingStepIndex === index ? (
                                                    <ItemsDropdown
                                                        items={availableSteps}
                                                        attr="name"
                                                        selected={selectedSpider}
                                                        setSelected={handleStepSelect}
                                                        isLoading={availableSteps.length === 0}
                                                        placeholder="Select Spider"
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        value={step.name}
                                                        readOnly
                                                    />
                                                )}
                                            </Form.Group>
                                        </div>
                                    ))}
    
                                    {/* Step Creation Section */}
                                    {isAddingStep && editingStepIndex === null && (
                                        <>
                                            <Form.Group controlId="stepSpider" className="mt-3">
                                                <Form.Label>Spider</Form.Label>
                                                <ItemsDropdown
                                                    items={availableSteps}
                                                    attr="name"
                                                    selected={selectedSpider}
                                                    setSelected={handleStepSelect}
                                                    isLoading={false}
                                                    placeholder="Select Spider"
                                                />
                                            </Form.Group>
    
                                            {selectedSpider.id && (
                                                <>
                                                    <Button
                                                        variant="pf23"
                                                        onClick={handleAddStep}
                                                        disabled={!isStepAdditionValid()}
                                                        className='mt-3 create-chained-spider-button'
                                                    >
                                                        Confirm Step
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
    
                                    {/* Add New Step Button */}
                                    {!isAddingStep && (
                                        <Button
                                            variant="pf23"
                                            onClick={handleAddNewStep}
                                            className='mt-3 create-chained-spider-button'
                                        >
                                            Add new step
                                        </Button>
                                    )}
                                </Form.Group>
                            )}
    
                            <hr />
                            <Button
                                variant="pf23"
                                type="submit"
                                className='mt-3'
                            >
                                Create Chained Spider
                            </Button>
                        </Form>
    
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CreateChainedSpider;