import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import SchedulesTable from "../tables/SchedulesTable";

import Delayed from "../DelayedComponent";

import { ReactComponent as DeleteIcon } from '../../assets/icon09_delete.svg';
import { ReactComponent as ToggleIconOFF } from '../../assets/icon12_toogle_off.svg';
import { ReactComponent as ToggleIconON } from '../../assets/icon12_toogle_on.svg';

// import ItemsDropdown from "../ItemsDropdown";
// import SelectOrAddProject from "../SelectOrAddProject";
// import SelectOrUploadFile from "../SelectOrUploadFile";
// import WarningModal from "./WarningModal";

// import ProjectContext from "../contexts/ProjectContext";

// props.showModal, props.setShowModal
// props.spider


const ManageSchedulesModal = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [periodicTasks, setPeriodicTasks] = useState([]);

    const [newCrontabSchedule, setNewCrontabSchedule] = useState("* * * * *");

    const [error, setError] = useState("")

    useEffect(()=>{
        if (props.spider){
            fetchPeriodicTasks();
        }
    }, [props.spider])
    
    function closeModal(){
        setNewCrontabSchedule("* * * * *");
        props.setShowModal(false);
        setError("");
    }

    function fetchPeriodicTasks() {
        setIsLoading(true);
        let spiderId = props.spider.id;
        let spiderType = props.spider_type;
        let queryParam;
    
        if (spiderType === 'chained') {
            queryParam = `chainedspidergroup=${spiderId}`;
        } else if (spiderType === 'regular' || spiderType === 'webscraper') {
            queryParam = `spider_id=${spiderId}`;
        } else {
            setError("Unsupported spider type");
            setIsLoading(false);
            return;
        }
    
        props.client.getScheduledPeriodicTasks(queryParam)
            .then(
                (response) => {
                    setPeriodicTasks(response.data);
                    setIsLoading(false);
                }
            )
            .catch(
                (error) => {
                    console.log(error);
                    setIsLoading(false);
                }
            );
    }

    function createCrontabScheduledPeriodicTask(userId, spiderId, spiderRegion, crontabExpression, spiderType) {
        return props.client.createNewCrontabSchedule(crontabExpression).then(
            (response) => {
                const crontabId = response.data.id;
                if (spiderType === 'chained') {
                    return props.client.createChainedSpiderTask(userId, spiderId, spiderRegion, crontabId, props.spiderPipeline);
                } else if (spiderType === 'webscraper') {
                    return props.client.createWebscraperTask(userId, spiderId, spiderRegion, crontabId, props.webscraperAccount, props.webscraperSitemapId);
                } else if (spiderType === 'regular') {
                    return props.client.createRegularSpiderTask(userId, spiderId, spiderRegion, crontabId);
                } else {
                    return Promise.reject(new Error("Spider type not correct"));
                }
            }
        );
    }

    function handlePeriodicTaskCreate() {
        let crontabs = periodicTasks.map(function (el) { return el.crontab_schedule; });
        if (!crontabs.includes(newCrontabSchedule)) {
            createCrontabScheduledPeriodicTask(props.userData.id, props.spider.id, props.spider.region, newCrontabSchedule, props.spider_type)
                .then((response) => {
                    fetchPeriodicTasks();
                    setNewCrontabSchedule("* * * * *");
                    setError("");
                })
                .catch(error => {
                    console.log(error.response?.data || error.message);
                    setError(error.response?.data || error.message);
                });
        }
    }
    
    function handlePeriodicTaskEnable(task){
        if (task.enabled === true){
            props.client.changeEnablePeriodicTask(task.id, false).then(
                (response) => {
                    fetchPeriodicTasks();
                }
            )
        }else{
            props.client.changeEnablePeriodicTask(task.id, true).then(
                (response) => {
                    fetchPeriodicTasks();
                }
            )
        }
    }

    function handlePeriodicTaskDelete(task){
        props.client.deletePeriodicTask(task.id).then(
            (response) => {
                fetchPeriodicTasks();
            }
        )
    }

    return (
        <Modal size="lg" show={props.showModal} onHide={(e) => {closeModal();}}>
            <Modal.Header closeButton>
            <Modal.Title>Manage Spider Schedules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Delayed waitBeforeShow="800">
                    <Container className="px-4">
                        <Row className="mb-1 mt-3">
                            <Col sm={12} md={3}>
                                Add new schedule:
                            </Col>
                            <Col sm={12} md={7}>
                                <Form>
                                    <Form.Group>
                                        <Form.Control
                                        className="w-100"
                                            type="text"
                                            placeholder="New crontab schedule"
                                            value={newCrontabSchedule}
                                            isInvalid={!!error}
                                            onChange={(e) => setNewCrontabSchedule(e.target.value)}
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        {error.minute}{error.minute && <br/>}
                                        {error.hour}{error.hour && <br/>}
                                        {error.day_of_week}{error.day_of_week && <br/>}
                                        {error.day_of_month}{error.day_of_month && <br/>}
                                        {error.month_of_year}{error.month_of_year && <br/>}
                                    </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                            (minute) (hour) (day of month) (month of year) (day of week) 
                                            <a href="https://crontab.guru/" target="_blank">[crontab info]</a>
                                        </Form.Text>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm={12} md={2}>
                            <Button 
                                    variant="modal"
                                    className="rounded-pill"
                                    onClick={(e) => {
                                        handlePeriodicTaskCreate();
                                    }}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                        <hr/>
                        <Row className='mb-3 py-1 bg-white d-flex align-items-center'>
                            <SchedulesTable
                                periodicTasks={periodicTasks}
                                handleEnable={handlePeriodicTaskEnable}
                                handleDelete={handlePeriodicTaskDelete}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        </Row>
                    </Container>
                </Delayed>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="modal"
                    className="rounded-pill"
                    onClick={(e) => closeModal()}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ManageSchedulesModal;