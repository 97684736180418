import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaSyncAlt } from "react-icons/fa";
import Tooltip from 'react-bootstrap/Tooltip';

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import SpiderContext from '../components/contexts/SpiderContext';

import { ReactComponent as ScheduleIcon } from '../assets/icon05_details.svg';
import { ReactComponent as CancelIconOff } from '../assets/icon14_cancel.svg';
import DeleteIconWithWarning from '../components/DeleteIconWithWarning';
import CancelIconWithWarning from '../components/CancelIconWithWarning';
import PaginationComponent from '../components/ApiPagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import config from '../config';
import DjangoAPIClient from '../client';

const client = new DjangoAPIClient(config);

const ChainedSpiderTaskResultsTable = ({ title, userData }) => {
    const location = useLocation();
    const [spiderContext, setSpiderContext] = useContext(SpiderContext);
    const { chainedSpiderId, spiderContext: locationSpiderContext } = location.state || {};
    const effectiveSpiderContext = locationSpiderContext || spiderContext;

    const [initialRender, setInitialRender] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [dataTable, setDataTable] = useState([]);
    const [dataSize, setDataSize] = useState(0);
    const [requestData, setRequestData] = useState(true);
    const [refreshData, setRefreshData] = useState(0);

    const [activePage, setActivePage] = useState(1);
    const [sortColumn, setSortColumn] = useState("creation_date");
    const [sortDirection, setSortDirection] = useState("-");
    const [filters, setFilters] = useState({
        task_id: '',
        created_at: '',
        done_at: '',
        status: [],
    });
    const statusOptions = [
        { value: 'SENT', label: 'SENT' },
        { value: 'SUBMITTED', label: 'SUBMITTED' },
        { value: 'SUCCESS', label: 'SUCCESS' },
        { value: 'FAILURE', label: 'FAILURE' },
        { value: 'ABORTED', label: 'ABORTED' },
        { value: 'STARTED', label: 'STARTED' },
        { value: '-', label: '-' },
    ];

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        // If we have spider context from navigation, update the context
        if (locationSpiderContext) {
            setSpiderContext(locationSpiderContext);
        }
    }, [locationSpiderContext, setSpiderContext]);

    useEffect(() => {
        if (requestData && chainedSpiderId) {
            fetchDataTable(setIsLoading, setDataTable, setDataSize, chainedSpiderId);
        }
    }, [activePage, sortColumn, sortDirection, filters, requestData, chainedSpiderId, refreshData]);

    function fetchDataTable(setIsLoading, setDataTable, setDataSize, chainedSpiderId) {
        setIsLoading(true);
        client.getChainedSpiderTaskResults(activePage, sortColumn, sortDirection, filters, chainedSpiderId)
            .then(
                (response) => {
                    setDataTable(response.data.results);
                    setDataSize(response.data.count);
                }
            )
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            setIsLoading(false);
        }
    }, [dataTable]);

    function handleSort(column) {
        setActivePage(1);
        if (sortColumn === column) {
            setSortDirection((prevSortDirection) =>
                prevSortDirection === "+" ? "-" : "+"
            );
        } else {
            setSortColumn(column);
            setSortDirection("+");
        }
    }

    function handleStatusCheckboxChange(status, checked) {
        if (checked) {
            setFilters({ ...filters, status: filters.status + status });
        } else {
            setFilters({ ...filters, status: filters.status.filter((s) => s !== status) });
        }
    }

    const handleItemClick = (value) => {
        const isChecked = filters.status.includes(value);
        if (isChecked) {
            setFilters({ ...filters, status: filters.status.filter((status) => status !== value) });
        } else {
            setFilters({ ...filters, status: [...filters.status, value] });
        }
    };

    const getSelectedStatusText = () => {
        if (filters.status.length === 0) {
            return 'Filter by status';
        } else if (filters.status.length === 1) {
            const selectedOption = statusOptions.find(
                (option) => option.value === filters.status[0]
            );
            return selectedOption.label;
        } else {
            return `${filters.status.length} selected`;
        }
    };

    const handleKeyDown = (event, setRequestData) => {
        if (event.key === 'Enter') {
            setRequestData(true);
        }
    }

    function deleteTaskResult(taskResult, setIsLoading, setDataTable, setDataSize) {
        setIsLoading(true);
        client.deleteTaskResult(taskResult.task_id)
            .then(
                (response) => {
                    fetchDataTable(setIsLoading, setDataTable, setDataSize);
                }
            );
    }

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                    <header className="bg-white pt-2">
                        <h1 className='text-start'>My tasks</h1>
                    </header>
                </Row>
                <Row className="px-3 py-2">
                        <Col sm={8} md={3}>
                        </Col>
                        <Col sm={4} md={9} className="refresh">
                            <Button
                                type="button"
                                variant="dark"
                                className="rounded-circle"
                                onClick={(e) => {
                                    setRefreshData(refreshData + 1);
                                }}
                            >
                                <FaSyncAlt />
                            </Button>
                        </Col>
                    </Row>
                <section>
                    <Table striped responsive bordered hover className='align-middle custom-table'>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort("task_id")} className='sort-arrow'>
                                    Task ID
                                    <span>
                                        {sortColumn === "task_id" && sortDirection === "+" && <FaSortUp />}
                                        {sortColumn === "task_id" && sortDirection === "-" && <FaSortDown />}
                                        {sortColumn !== "task_id" && <FaSort />}
                                    </span>
                                </th>
                                <th onClick={() => handleSort("created_at")} className='sort-arrow'>
                                    Date started
                                    <span className="sort-arrow">
                                        {sortColumn === "created_at" && sortDirection === "+" && <FaSortUp />}
                                        {sortColumn === "created_at" && sortDirection === "-" && <FaSortDown />}
                                        {sortColumn !== "created_at" && <FaSort />}
                                    </span>
                                </th>
                                <th onClick={() => handleSort("done_at")} className='sort-arrow'>
                                    Date finished
                                    <span className="sort-arrow">
                                        {sortColumn === "done_at" && sortDirection === "+" && <FaSortUp />}
                                        {sortColumn === "done_at" && sortDirection === "-" && <FaSortDown />}
                                        {sortColumn !== "done_at" && <FaSort />}
                                    </span>
                                </th>
                                <th onClick={() => handleSort("status")} className='sort-arrow'>
                                    Status
                                    <span className="sort-arrow">
                                        {sortColumn === "status" && sortDirection === "+" && <FaSortUp />}
                                        {sortColumn === "status" && sortDirection === "-" && <FaSortDown />}
                                        {sortColumn !== "status" && <FaSort />}
                                    </span>
                                </th>
                                <th>Delete</th>
                            </tr>
                            <tr>
                                <th>
                                    <Form.Control
                                        type="text"
                                        id="filter-id"
                                        placeholder="Filter by task ID"
                                        value={filters.task_id}
                                        onBlur={(e) => { setActivePage(1); setRequestData(true) }}
                                        onChange={(e) => { setRequestData(false); setFilters({ ...filters, task_id: e.target.value }) }}
                                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        id="filter-created"
                                        placeholder="Filter by date and time"
                                        value={filters.created_at}
                                        onBlur={(e) => { setActivePage(1); setRequestData(true) }}
                                        onChange={(e) => { setRequestData(false); setFilters({ ...filters, created_at: e.target.value }) }}
                                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        id="filter-done"
                                        placeholder="Filter by date and time"
                                        value={filters.done_at}
                                        onBlur={(e) => { setActivePage(1); setRequestData(true) }}
                                        onChange={(e) => { setRequestData(false); setFilters({ ...filters, done_at: e.target.value }) }}
                                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                                    />
                                </th>
                                <th>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="filter" id="filter-dropdown">
                                            {getSelectedStatusText()}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {statusOptions.map((option) => (
                                                <Dropdown.Item
                                                    key={option.value}
                                                    onClick={() => { handleItemClick(option.value) }}
                                                    className='py-0'
                                                >
                                                    <Form.Check style={{ cursor: "pointer" }}>
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            style={{ cursor: "pointer" }}
                                                            id={`status-${option.value}`}
                                                            checked={filters.status.includes(option.value)}
                                                            onChange={(e) =>
                                                                handleStatusCheckboxChange(option.value, e.target.checked)
                                                            }
                                                        />
                                                        <Form.Check.Label htmlFor={`status-${option.value}`} style={{ cursor: "pointer" }}>
                                                            {option.label}
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <tr><td colSpan="11" className="text-center">Loading...</td></tr>}
                            {!isLoading && dataSize === 0 && <tr><td colSpan="11" className="text-center">No tasks found or tasks have been archived.</td></tr>}
                            {!isLoading && dataTable.map((task, index) => {
                                return (
                                    <tr key={index}>
                                        <OverlayTrigger
                                            overlay={<Tooltip>{copied ? 'Copied!' : 'Click to copy'}</Tooltip>}
                                            placement="right"
                                            delayShow={0}
                                            delayHide={150}
                                        >
                                            <td
                                                style={{ cursor: 'default' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(task.task_id);
                                                    setCopied(true);
                                                    setTimeout(() => setCopied(false), 500); // Reset copied state after 0.5 seconds
                                                }}
                                            >
                                                {task.task_id}
                                            </td>
                                        </OverlayTrigger>
                                        <td>{task.created_at}</td>
                                        <td>{task.done_at}</td>
                                        <td>{task.status}</td>
                                        <td>
                                            <DeleteIconWithWarning
                                                handleDelete={(e) => { deleteTaskResult(task, setIsLoading, setDataTable, setDataSize) }}
                                                warningBodyText={
                                                    <a>You are about to delete task, all related data from MongoDB & logs. Do you want to continue?</a>
                                                }
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Col>
                        <PaginationComponent
                            pageLimit={10}
                            countAllResults={dataSize}
                            activePage={activePage}
                            setActivePage={setActivePage}
                        />
                    </Col>
                </section>
            </Container>

        </>
    );
};

export default ChainedSpiderTaskResultsTable;