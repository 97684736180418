import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Alert from 'react-bootstrap/Alert';

import config from '../config';
import DjangoAPIClient from '../client';
import ItemsDropdown from '../components/ItemsDropdown';

const client = new DjangoAPIClient(config);

const ChainedSpiderDetailView = ({ title, userData }) => {
    const { chainedSpiderId } = useParams();
    const [error, setError] = useState(null);
    const [chainedSpider, setChainedSpider] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({ steps: [] });
    const [availableSteps, setAvailableSteps] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        document.title = title;
        fetchSpiders();
        fetchChainSpider();
    }, [title, chainedSpiderId]);

    useEffect(() => {
        if (chainedSpider && !editMode) {
            setFormData({ ...chainedSpider, steps: chainedSpider.steps || [] });
        }
    }, [chainedSpider, editMode]);

    const fetchSpiders = async () => {
        try {
            const response = await client.getAllDropdownChainedSpiders();
            setAvailableSteps(response.data);
        } catch (error) {
            console.error('Error fetching available steps:', error);
        }
    };

    function fetchChainSpider() {
        client.getChainSpider(chainedSpiderId)
            .then((response) => {
                const data = response.data;
                setChainedSpider(data);
                setFormData({ ...data, steps: data.steps || [] });
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    function handleSpiderSelect(index, selectedSpider) {
        setFormData(prevData => {
            const updatedSteps = [...prevData.steps];
            updatedSteps[index] = {
                ...updatedSteps[index],
                spider_id: selectedSpider.id,
                spider_name: selectedSpider.name
            };
            return {
                ...prevData,
                steps: updatedSteps
            };
        });
    }

    function addStep() {
        setFormData(prevData => ({
            ...prevData,
            steps: [...prevData.steps, { spider_id: '', engine: '', page_type: '' }]
        }));
    }

    function removeStep(index) {
        setFormData(prevData => {
            const updatedSteps = [...prevData.steps];
            updatedSteps.splice(index, 1);
            return {
                ...prevData,
                steps: updatedSteps
            };
        });
    }

    function makeFieldsEditable(event) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        setEditMode(true);
    }

    function handleSubmit(event) {
        event.preventDefault();
        client.updateChainedSpider(chainedSpiderId, formData)
            .then((response) => {
                setChainedSpider(response.data.data);
                setEditMode(false);
                setSuccessMessage('Chained Spider updated successfully!');
            })
            .catch((error) => {
                console.error(error);
                setError(error);
            });
    }

    function handleCancel() {
        setEditMode(false);
        if (chainedSpider) {
            setFormData({ ...chainedSpider, steps: chainedSpider.steps || [] });
        } else {
            setFormData({ steps: [] });
        }
    }

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    return (
        // ... JSX remains unchanged
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <section>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center min-vh-100">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : error ? (
                        <div>Error: {error.message}</div>
                    ) : (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} md={9} className="mx-auto">
                                        <Form.Group className="mt-4">
                                            <Form.Label>Chained Spider Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name || ''}
                                                onChange={handleInputChange}
                                                disabled={!editMode}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mt-4 mb-4">
                                            <Form.Label>Created At</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="created_at"
                                                value={formData.created_at || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mt-4 mb-4">
                                            <Form.Label>Updated At</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="updated_at"
                                                value={formData.updated_at || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                        {formData.steps.map((step, index) => (
                                            <Card key={index} className="mb-4">
                                                <Card.Header>
                                                    <h5>Step {index + 1}</h5>
                                                    <Button
                                                        variant="dark"
                                                        onClick={() => window.open(`/spiders/${step.spider_id}`, '_blank')}
                                                    >
                                                        Go to spider page
                                                    </Button>
                                                    {editMode && (
                                                        <Button
                                                            variant="danger"
                                                            className="mx-5"
                                                            onClick={() => removeStep(index)}
                                                        >
                                                            Remove Step
                                                        </Button>
                                                    )}
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row className="mx-3 my-3 align-items-center">
                                                        <Col className="d-flex align-items-center">
                                                            Spider:
                                                        </Col>
                                                        <Col sm={12} md={9}>
                                                            {editMode ? (
                                                                <ItemsDropdown
                                                                    items={availableSteps}
                                                                    attr="name"
                                                                    selected={{ id: step.spider_id, name: step.spider_name }}
                                                                    setSelected={(selectedSpider) => handleSpiderSelect(index, selectedSpider)}
                                                                    isLoading={availableSteps.length === 0}
                                                                    placeholder="Select Spider"
                                                                />
                                                            ) : (
                                                                <Form.Control
                                                                    type="text"
                                                                    value={step.spider_name || ''}
                                                                    readOnly
                                                                />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                        {editMode && (
                                            <Row className="mx-3 my-3">
                                                <Col>
                                                    <Button
                                                        variant="dark"
                                                        onClick={addStep}
                                                    >
                                                        Add New Step
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className="mx-3 my-3">
                                            <Col>
                                                {editMode ? (
                                                    <>
                                                        <Button variant="pf23" type="submit">
                                                            Submit Changes
                                                        </Button>
                                                        <Button variant="secondary" onClick={handleCancel} className="ms-3" style={{ 'padding': '0.75rem 1rem' }}>
                                                            Cancel
                                                        </Button>
                                                    </>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            <hr></hr>
                            {!editMode && (
                                <Row className="mx-3">
                                    <Col>
                                        <Button
                                            variant="pf23"
                                            onClick={makeFieldsEditable}
                                            className='mb-3'
                                        >
                                            Update Chained Spider
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    {successMessage && (
                        <Alert variant="success" className="mt-3 position-fixed w-50" onClose={() => setSuccessMessage(null)} dismissible>
                            {successMessage}
                        </Alert>
                    )}
                </section>
            </Container>
        </>
    );
}

export default ChainedSpiderDetailView;