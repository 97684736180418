import {React, useContext, useState, useRef, useEffect} from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { FaBars, FaTable, FaFileCode } from "react-icons/fa";
import { GiBreakingChain } from "react-icons/gi";
import { NavLink, useNavigate, Link } from 'react-router-dom';
import DEA_logo_white from '../assets/Scrapfinder logo06.svg';
import PF23_logo from '../assets/PF23_logo-01.svg';
import { ReactComponent as ProjectIcon } from '../assets/icon01_project.svg';
import { ReactComponent as SpiderIcon } from '../assets/icon02_spider.svg';
import { ReactComponent as TaskIcon } from '../assets/icon06_tasks.svg';
import ScrapedData from '../pages/ScrapedData';

// import ProjectContext from './contexts/ProjectContext';
// import SpiderContext from './contexts/SpiderContext';

const Sidebar = ({ userData }) => {
  // const [projectContext, setProjectContext] = useContext(ProjectContext);
  // const [spiderContext, setSpiderContext] = useContext(SpiderContext);
  const [showCanvas, setShowCanvas] = useState(false);
  const navigate = useNavigate();

  const [reportPage, setReportPage] = useState(false);
  const [insightAIManualPage, setInsightAIManualPage] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);

  function getPermissionFromUserData() {
    if (userData && Object.keys(userData).length > 0) {
      const reportPage = userData.permission_names.find((per) => true ? per == "Can add pbi report": undefined) ? true : false;
      setReportPage(reportPage);
    } else {
      setReportPage(false);
    }
  }

  function hasInsightAIPermission(userData) {
    return userData && userData.is_insightai === true;
  }

  function hasSuperUserPermission(userData) {
    return userData && userData.is_superuser === true;
  }

  useEffect(() => {
    getPermissionFromUserData();
  }, [userData])

  useEffect(() => {
    if (userData) {
      setIsSuperUser(hasSuperUserPermission(userData));
      setInsightAIManualPage(hasInsightAIPermission(userData));
    }
  }, [userData])

  const toogleOffcanvas = () => {
    setShowCanvas((showCanvas) => !showCanvas);
  }

  const toSpiderPage = () => {
    navigate("/my-spiders");
  };

  const toTaskResultsPage = () => {
    // setSpiderContext();
    navigate("/my-tasks");
  };

  const toMyFilesPage = () => {
    navigate("/project-files");
  };

  const toEretailersPage = () => {
    navigate("/eretailers");
  };

  const toScrapedDataPage = () => {
    navigate("/scraped-data");
  };

  const toInsightAIManualPipeline = () => {
    navigate("/insightai-manual-pipeline");
  };

  const toChainedSpiders = () => {
    navigate("/chained-spiders");
  };

  const toSpiderPageMobile = () => {
    navigate("/my-spiders");
    toogleOffcanvas()
  };

  const toTaskResultsPageMobile = () => {
    navigate("/my-tasks");
    toogleOffcanvas()
  };

  const toMyFilesPageMobile = () => {
    navigate("/project-files");
    toogleOffcanvas()
  };

  const toEretailersPageMobile = () => {
    navigate("/eretailers");
    toogleOffcanvas()
  };

  const toReports = () => {
    navigate("/pbi/reports");
  };

  const toReportsPageMobile = () => {
    navigate("/pbi/reports");
    toogleOffcanvas()
  };

  const toScrapedDataPageMobile = () => {
    navigate("/scraped-data");
  };

  const toInsightAIManualPipelineMobile = () => {
    navigate("/insightai-manual-pipeline");
  };

  const toChainedSpidersMobile = () => {
    navigate("/chained-spiders");
  };

  const handleToggleCanvas = () => setShowCanvas((prev) => !prev);


  return (
    <Navbar bg="bg-dark-pf23" variant="dark" expand="md" fixed="left" className="d-flex align-items-start flex-column position-fixed vh-100 px-4 overflow-sidebar" >
      <Navbar.Brand className='ps-3 pt-1 hide-on-mobile mobile-header-border w-100'>
        <Link to="/dea" className='brand-logo'>
          <img src={DEA_logo_white} className="scrapfinder-logo pe-2 pb-1" alt="logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav" className='align-items-start hide-on-mobile'>
        <Nav className="flex-column py-4">
          <Nav.Link as={Link} to='/my-projects' className='py-4'>
            <ProjectIcon className='sidebar-icon' />
            Project
          </Nav.Link>
          <Nav.Link onClick={toSpiderPage} className='py-4'>
            <SpiderIcon className='sidebar-icon' />
            Spider
          </Nav.Link>
          {isSuperUser &&
          <>
          <Nav.Link onClick={toChainedSpiders} className='py-4' style={{margin: '6px 0px'}}>
            <GiBreakingChain className='sidebar-icon' />
            Chained Spiders
          </Nav.Link>
          {/* <Nav.Link onClick={toScrapedDataPage} className='py-4' style={{margin: '6px 0px'}}>
            <FaTable className='sidebar-icon' />
            Scraped Data
          </Nav.Link> */}
          </>
          }
          <Nav.Link onClick={toTaskResultsPage} className='task py-4'>
            <TaskIcon className='sidebar-icon' />
            Tasks
          </Nav.Link>
          <Nav.Link onClick={toMyFilesPage} className='py-4' style={{margin: '6px 0px'}}>
            <FaFileCode className='sidebar-icon' />
            Project files
          </Nav.Link>
          <Nav.Link onClick={toEretailersPage} className='py-4' style={{margin: '6px 0px'}}>
            <FaTable className='sidebar-icon' />
            E-retailers
          </Nav.Link>
          {reportPage &&
            <Nav.Link onClick={toReports} className='task py-4'>
              <TaskIcon className='sidebar-icon'/>
              Reports
            </Nav.Link>
          }
          {insightAIManualPage &&
          <Nav.Link onClick={toInsightAIManualPipeline} className='py-4' style={{margin: '6px 0px'}}>
            <FaTable className='sidebar-icon' />
            InsightAI Manual
          </Nav.Link>
          }
        </Nav>
      </Navbar.Collapse>
      <div className='d-flex align-items-end sidebar-footer hide-on-mobile'>
        &copy; Copyright {(new Date().getFullYear())}
        <Link to="https://brandnewgalaxy.com" target="_blank"><img src={PF23_logo} className='pf23-logo'></img></Link>
      </div>
      <Button variant="none" size="lg" onClick={handleToggleCanvas} className="mobile-menu">
          <FaBars value={{ color: "black" }}/>
      </Button>

    <Offcanvas show={showCanvas} onHide={handleToggleCanvas} className="position-absolute vh-100">
    <Offcanvas.Header className='mobile-header-border' closeButton>
      <Offcanvas.Title>
      <Link to="/" className='brand-logo' onClick={toogleOffcanvas}>
          <img src={DEA_logo_white} className="scrapfinder-logo pb-1" alt="logo" />
        </Link>
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <Nav className="flex-column">
        <Nav.Link as={Link} to="/my-projects" onClick={toogleOffcanvas}>
            <ProjectIcon className='sidebar-icon' />
            Project
          </Nav.Link>
          <Nav.Link onClick={toSpiderPageMobile} className='py-4'>
            <SpiderIcon className='sidebar-icon' />
            Spider
          </Nav.Link>
          {isSuperUser &&
          <Nav.Link onClick={toChainedSpiders} className='py-4' style={{margin: '6px 0px'}}>
            <GiBreakingChain className='sidebar-icon' />
            Chained Spiders
          </Nav.Link>
            // <Nav.Link onClick={toScrapedDataPageMobile} className='py-4' style={{margin: '6px 0px'}}>
            //   <FaTable className='sidebar-icon' />
            //   Scraped Data
            // </Nav.Link>
          }
          <Nav.Link onClick={toTaskResultsPageMobile} className='task py-4'>
            <TaskIcon className='sidebar-icon' />
            Tasks
          </Nav.Link>
          <Nav.Link onClick={toMyFilesPageMobile} className='py-4' style={{margin: '6px 0px'}}>
            <FaFileCode className='sidebar-icon' />
            Project files
          </Nav.Link>
          <Nav.Link onClick={toEretailersPageMobile} className='py-4' style={{margin: '6px 0px'}}>
            <FaTable className='sidebar-icon' />
            E-retailers
          </Nav.Link>
          {reportPage &&
            <Nav.Link onClick={toReportsPageMobile} className='task py-4'>
              <TaskIcon className='sidebar-icon'/>
              Reports
            </Nav.Link>
          }
          {insightAIManualPage &&
            <Nav.Link onClick={toInsightAIManualPipelineMobile} className='py-4' style={{margin: '6px 0px'}}>
              <FaTable className='sidebar-icon' />
              InsightAI Manual
            </Nav.Link>
          }
      </Nav>
      <div className='d-flex align-items-end sidebar-footer position-fixed bottom-0 pb-3'>
        &copy; Copyright {(new Date().getFullYear())}
        <Link to="https://brandnewgalaxy.com" target="_blank"><img src={PF23_logo} className='pf23-logo'></img></Link>
      </div>
    </Offcanvas.Body>
    </Offcanvas>
    </Navbar>
  );
};

export default Sidebar;