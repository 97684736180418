import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { CSSTransition } from 'react-transition-group';
import { FaSyncAlt } from "react-icons/fa";

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import ManageSchedulesModal from '../components/modals/ManageSchedulesModal';
import ItemsDropdown from '../components/ItemsDropdown';
import SpidersTable from '../components/tables/SpidersTable';
import ProjectContext from '../components/contexts/ProjectContext';
import SpiderContext from '../components/contexts/SpiderContext';

import config from '../config';
import DjangoAPIClient from '../client';

const client = new DjangoAPIClient(config);


const SpidersPage = ({ title, userData }) => {
	const navigate = useNavigate();
	const [projectContext, setProjectContext] = useContext(ProjectContext);
	const [spiderContext, setSpiderContext] = useContext(SpiderContext);

	const [myProjects, setMyProjects] = useState([]);
	const [dropdownLoading, setDropdownLoading] = useState(false);

	const [refreshData, setRefreshData] = useState(0);

	const [showSchedulesModal, setShowSchedulesModal] = useState(false);

	const [error, setError] = useState({ statusText: "" })
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState({ statusText: "", project: "", mongodb_collection_name: "", spider_config_file: "", template_file: "", yaml_config_file: "" });
	// determine spider type based on is_webscraper attribute from spiderContext
	const spiderType = spiderContext.is_webscraper ? "webscraper" : "regular";
	////////// Page title & mobile detection
	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
	//////////

	useEffect(() => {
		fetchMyProjects();
	}, [])

	function fetchMyProjects() {
		setDropdownLoading(true);
		client.getDropdownProjects()
			.then(
				(response) => {
					const projects = response.data.filter(function (el) { return el.name != "Generic spiders"; });
					setMyProjects(projects);
					if (projectContext.id === 0) {
						setProjectContext(response.data[0]);
					}
					setDropdownLoading(false);
				}
			)
			.catch((error) => {
				console.log(error)
			});
	}

	return (
		<>
			<Sidebar userData={userData} />
			<Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
				<Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
				<CSSTransition
					in={showAlert}
					timeout={300}
					classNames="alert"
					unmountOnExit
				>
					<Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
						{alertMessage.project}{alertMessage.project && <br />}
						{alertMessage.mongodb_collection_name}{alertMessage.mongodb_collection_name && <br />}
						{alertMessage.spider_config_file}{alertMessage.spider_config_file && <br />}
						{alertMessage.template_file}{alertMessage.template_file && <br />}
						{alertMessage.yaml_config_file}{alertMessage.yaml_config_file && <br />}
						{alertMessage.statusText}{alertMessage.statusText && <br />}
					</Alert>
				</CSSTransition>
				<ManageSchedulesModal
					client={client}
					userData={userData}
					showModal={showSchedulesModal}
					setShowModal={setShowSchedulesModal}
					spider={spiderContext}
					error={error}
                    spider_type={spiderType}
                    {...(spiderType === 'webscraper' && {
                        webscraperAccount: spiderContext.webscraper_account,
                        webscraperSitemapId: spiderContext.webscraper_sitemap_id
                    })}
				/>

				<section>
					<Row className='px-3 py-1 bg-white d-flex align-items-center'>
						<Col sm={12} md={6} className='header'>
							<header className="bg-white pt-2">
								<h1 className='text-start'>My spiders</h1>
							</header>
						</Col>
						<Col sm={12} md={6} className='d-flex justify-content-md-end justify-content-sm-start'>
							<Button
								className='rounded-pill'
								variant='pf23'
								onClick={(e) => navigate("/create-spider")}
								error={error}
							>
								Create Spider
							</Button>
						</Col>
					</Row>

					<Row className="px-3 py-2">
						<Col sm={8} md={3}>
							<ItemsDropdown
								items={myProjects}
								selected={projectContext}
								setSelected={setProjectContext}
								attr={"name"}
								isLoading={dropdownLoading}
							/>
						</Col>
						<Col sm={4} md={9} className="refresh">
							<Button
								type="button"
								variant="dark"
								className="rounded-circle"
								onClick={(e) => {
									setRefreshData(refreshData + 1);
								}}
							>
								<FaSyncAlt />
							</Button>
						</Col>
					</Row>

					<Row className='px-3 py-2'>
						<SpidersTable
							client={client}
							userId={userData.id}
							project={projectContext}
							setSpider={setSpiderContext}
							setShowSchedulesModal={setShowSchedulesModal}
							maxPagination={10}
							refreshData={refreshData}
							setRefreshData={setRefreshData}
							setShowAlert={setShowAlert}
							setAlertMessage={setAlertMessage}
							setError={setError}
						/>
					</Row>
				</section>
			</Container>
		</>
	)
}

export default SpidersPage;

