import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import SelectOrAddProject from "../components/SelectOrAddProject";
import SelectOrAddCollection from "../components/SelectOrAddCollection";
import SelectOrUploadFile from "../components/SelectOrUploadFile";
import Navbar from '../components/Navbar';
import { CSSTransition } from 'react-transition-group';
import Alert from 'react-bootstrap/Alert';

import config from '../config';
import DjangoAPIClient from '../client';
import ProjectContext from "../components/contexts/ProjectContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ItemsDropdown from '../components/ItemsDropdown';
import SelectOrAddSitemap from '../components/SelectOrAddSitemap';


const client = new DjangoAPIClient(config);

const CreateSpiderPage = ({ title, userData }) => {
    const [projectContext, setProjectContext] = useContext(ProjectContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCollections, setIsLoadingCollections] = useState(true);

    const navigate = useNavigate();

    // loaded projects/files:
    const [myProjects, setMyProjects] = useState([]);
    const [myCollections, setMyCollections] = useState([]);
    const [spiderConfigs, setSpiderConfigs] = useState([]);
    const [yamlConfigs, setYamlConfigs] = useState([]);
    const [templateFiles, setTemplateFiles] = useState([]);



    // data to create spider:
    const [newSpiderName, setNewSpiderName] = useState();
    const [selectedCollection, setSelectedCollection] = useState({ id: 0, collection_name: "Select" });
    const [selectedSpiderConfig, setSelectedSpiderConfig] = useState({ id: 0, file: "Select" });
    const [selectedYamlConfig, setSelectedYamlConfig] = useState({ id: 0, file: "Select" });
    const [selectedTemplateFile, setSelectedTemplateFile] = useState({ id: 0, file: "Select" });
    const [spiderKwargs, setSpiderKwargs] = useState("{}");

    const [validated, setValidated] = useState(false);
    const [error, setError] = useState({ statusText: "", project: "", mongodb_collection_name: "", spider_config_file: "", template_file: "", yaml_config_file: "", page_type: "" });    
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState({ statusText: "", project: "", mongodb_collection_name: "", spider_config_file: "", template_file: "", yaml_config_file: "", page_type: "" });

    const [genericSpiderConfigs, setGenericSpiderConfigs] = useState(false);
    const [projectSpiderConfigs, setProjectSpiderConfigs] = useState(true);
    const [genericYamlConfigs, setGenericYamlConfigs] = useState(false);
    const [projectYamlConfigs, setProjectYamlConfigs] = useState(true);
    const [pageType, setPageType] = useState('');

    const [isWebscraper, setIsWebscraper] = useState(false);
    const [selectedWebscraperAccount, setSelectedWebscraperAccount] = useState(null);
    const [webscraperAccount, setWebscraperAccount] = useState('');
    const [sitemaps, setSitemaps] = useState([]);
    const [selectedSitemap, setSelectedSitemap] = useState({ id: 0, name: "Select sitemap" });
    const [dropdownLoading, setDropdownLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [isInitialized, setIsInitialized] = useState(false);

    ////////// Page title
	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
    //////////

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

    };

    useEffect(() => {
        fetchMyProjects();
        fetchMyCollections();
        setIsInitialized(true);
        // fetchSpiderConfigs();
        // fetchYamlConfigs();
        // fetchTemplateFiles();
    }, [title])

    // Webscraper
    useEffect(() => {
        if (isWebscraper && selectedWebscraperAccount && isInitialized) {
            fetchWebscraperSitemaps();
        }
    }, [isWebscraper, selectedWebscraperAccount, isInitialized]);

    useEffect(() => {
        fetchSpiderConfigs();
        fetchYamlConfigs();
        fetchTemplateFiles();
    }, [projectContext, genericSpiderConfigs, genericYamlConfigs])

	function fetchMyProjects() {
		client.getDropdownProjects()
		.then(
			(response) => {
				setMyProjects(response.data);
				if (projectContext.id === 0) {
					setProjectContext(response.data[0]);
				}
                setIsLoading(false);
			}
		)
		.catch((error) => {
			console.log(error)
		});
	}

    function fetchMyCollections() {
        setIsLoadingCollections(true);
        client.getMyCollections().then(
            (response) => {
                setMyCollections(response.data);
                setIsLoadingCollections(false);
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function handleProjectCreate(newProjectName, is_shared, group) {
        client.createNewProject(newProjectName, is_shared, group).then(
            (response) => {
                setProjectContext({ id: response.data.id, name: response.data.name });
                fetchMyProjects();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function handleCollectionCreate(newCollectionName) {
        client.createNewCollection(newCollectionName).then(
            (response) => {
                setSelectedCollection({ id: response.data.id, collection_name: response.data.collection_name });
                fetchMyCollections();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function fetchSpiderConfigs() {
        const projectName = genericSpiderConfigs ? "Generic spiders" : projectContext.name;
        client.getDropdownSpiderConfigs(projectName).then(
            (response) => {
                setSpiderConfigs(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function fetchYamlConfigs() {
        const projectName = genericYamlConfigs ? "Generic spiders" : projectContext.name;
        client.getDropdownYamlConfigs(projectName).then(
            (response) => {
                setYamlConfigs(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function fetchTemplateFiles() {
        const projectName = projectContext.name;
        client.getDropdownTemplateFiles(projectName).then(
            (response) => {
                setTemplateFiles(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function toSpidersPage() {
        navigate("/my-spiders");
    }


    function handleSpiderCreate(
        projectId, spiderName, mongoCollectionId,
        spiderConfigId, yamlConfigId, templateId, 
        kwargs, pageType, isWebscraper, webscraperAccount, selectedSitemap
    ) {
        // Check if the spider name is provided
        if (!spiderName) {
            // Display the error message in the alert component
            setShowAlert(true);
            setAlertMessage({
                name: "Spider name must be provided.",
            });
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            return;
        }
    
        if (isWebscraper) {
            if (!selectedWebscraperAccount) {
                setShowAlert(true);
                setAlertMessage({
                    name: "Please select a Webscraper account.",
                });
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                return;
            }

            if (!selectedSitemap) {
                setShowAlert(true);
                setAlertMessage({
                    name: "Please select a Webscraper sitemap.",
                });
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                return;
            }
        }
    
        client.createNewSpider(projectId, spiderName, mongoCollectionId, spiderConfigId, yamlConfigId, templateId, kwargs, pageType, isWebscraper, selectedWebscraperAccount, selectedSitemap)
            .then(
                (response) => {
                    console.log(response.data);
                    toSpidersPage();
                }
            )
            .catch((error) => {
                console.log(error.response.data);
                setError(error.response.data);
                setShowAlert(true);
                setAlertMessage({
                    // Extract custom error messages from the response
                    project: error.response.data.project || "",
                    mongodb_collection_name: error.response.data.mongodb_collection || "",
                    spider_config_file: error.response.data.spider_config_file || "",
                    template_file: error.response.data.template_file || "",
                    yaml_config_file: error.response.data.yaml_config_file || ""
                });
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            });
    }
    

    function uploadSpiderConfig(newFile) {
        client.uploadSpiderConfig(newFile, projectContext.name).then(
            (response) => {
                console.log(response.data);
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedSpiderConfig({ id: response.data.data.id, file: short_name});
                fetchSpiderConfigs();
            }
        )
        .catch((error) => {
            console.log(error);
        });
    }
    

    function uploadYamlConfig(newFile) {
        client.uploadYamlConfig(newFile, projectContext.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedYamlConfig({ id: response.data.data.id, file: short_name });
                fetchYamlConfigs();
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function uploadTemplateFile(newFile) {
        client.uploadTemplateFile(newFile, projectContext.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedTemplateFile({ id: response.data.data.id, file: short_name });
                fetchTemplateFiles();
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    // Webscraper
    function fetchWebscraperSitemaps() {
        if (!isWebscraper || !selectedWebscraperAccount) return;
    
        // Reset selected sitemap when fetching new sitemaps for a different account
        setSelectedSitemap({ id: null, name: "Select Sitemap" });
    
        setDropdownLoading(true);
        return client.getWebscraperSitemaps(selectedWebscraperAccount)
            .then((response) => {
                setSitemaps(response.data.data);
                setDropdownLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response?.data || 'Error fetching sitemaps');
                setDropdownLoading(false);
                return Promise.reject(error);
            });
    }

    const handleAccountChange = (value) => {
        if (value !== selectedWebscraperAccount) {
            // Reset the sitemap selection when changing accounts
            setSelectedSitemap({ id: null, name: "Select Sitemap" });
            setSelectedWebscraperAccount(value);
            setWebscraperAccount(value);
        }
    };

    const handleSitemapCreate = (sitemapData) => {
        if (!isWebscraper || !selectedWebscraperAccount) return Promise.reject('Webscraper is disabled or no account selected');
        
        return client.createSitemap(sitemapData, selectedWebscraperAccount)
            .then((response) => {
                if (!response.data.success) {
                    // Instead of setting error to an object, set it to a string
                    setValidationError(response.data.validationErrors?.name || "Error creating sitemap");
                    return false;
                } else {
                    setSelectedSitemap({ id: response.data.id, name: response.data.name });
                    fetchWebscraperSitemaps();
                    setValidationError('');
                    return true;
                }
            })
            .catch((error) => {
                console.log(error);
                // Set validation error to a string instead of an object
                setValidationError(error.response?.data?.message || 'Error creating sitemap');
                return false;
            });
    };

    const handleDownloadSitemap = (e) => {
        if (e) e.preventDefault();
        
        if (!isWebscraper) {
            setError("Webscraper is disabled");
            return;
        }

        if (!selectedSitemap || !selectedSitemap.id) {
            setError("Please select a sitemap first");
            return;
        }

        setIsDownloading(true);
        setError('');
        
        client.getSitemap(selectedSitemap.id, selectedWebscraperAccount)
            .then((response) => {
                try {
                    const jsonData = JSON.stringify(response.data, null, 2);
                    const blob = new Blob([jsonData], { type: 'application/json' });
                    
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', `sitemap_${selectedSitemap.id}.json`);
                    
                    document.body.appendChild(link);
                    
                    setTimeout(() => {
                        link.click();
                        setTimeout(() => {
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(url);
                        }, 100);
                    }, 0);
                } catch (err) {
                    console.error("Download error:", err);
                    setError("Error processing download");
                }
                
                setIsDownloading(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response?.data || 'Error downloading sitemap');
                setIsDownloading(false);
            });
    };

    return (
        <>
            <Sidebar userData={userData}/>
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <CSSTransition
                    in={showAlert}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                        <strong>Submitted data incorrect!!!<br /></strong>
                        {alertMessage.name}{alertMessage.name && <br />}
                        {alertMessage.project}{alertMessage.project && <br />}
                        {alertMessage.mongodb_collection_name}{alertMessage.mongodb_collection_name && <br />}
                        {alertMessage.spider_config_file}{alertMessage.spider_config_file && <br />}
                        {alertMessage.template_file}{alertMessage.template_file && <br />}
                        {alertMessage.yaml_config_file}{alertMessage.yaml_config_file && <br />}
                        {alertMessage.statusText}{alertMessage.statusText && <br />}
                        {alertMessage.page_type}{alertMessage.page_type && <br />}
                    </Alert>
                </CSSTransition>

                    <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                            <header className="bg-white pt-2">
                                <h1 className='text-start'>Create new spider</h1>
                            </header>
                    </Row>
                    {isLoading && <div className='h3 p-4 text-start'>Loading...</div>}
                    {!isLoading &&
                    <section>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Project:
                            </Col>
                            <Col md={8}>
                                <SelectOrAddProject
                                    selectedProject={projectContext}
                                    setSelectedProject={setProjectContext}
                                    myProjects={myProjects}
                                    isInvalid={!!error}
                                    handleProjectCreate={handleProjectCreate}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.project}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Spider name:
                            </Col>
                            <Col className="ps-4 spider-name-md-5">
                                <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    className="rounded-pill"
                                    placeholder="Spider name"
                                    isInvalid={newSpiderName === ""}
                                    value={newSpiderName}
                                    onChange={(e) => setNewSpiderName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.name}
                                </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                MongoDB collection name:
                            </Col>
                            <Col md={8} className="ps-3">
                                <SelectOrAddCollection
                                    selectedCollection={selectedCollection}
                                    setSelectedCollection={setSelectedCollection}
                                    myCollections={myCollections}
                                    isInvalid={!!error.mongodb_collection_name}
                                    handleCollectionCreate={handleCollectionCreate}
                                    isLoading={isLoadingCollections}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {/* Field cannot be empty */}
                                    error.mongodb_collection_name
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Spider config file (generic files&nbsp;
                                    <Form.Check
                                        type="checkbox"
                                        checked={genericSpiderConfigs}
                                        onChange={(e) => {
                                            setGenericSpiderConfigs(e.target.checked);
                                            setProjectSpiderConfigs(!e.target.checked);
                                        }}
                                    />):
                            </Col>
                            <Col md={8}>
                                <SelectOrUploadFile
                                    selected={selectedSpiderConfig}
                                    setSelected={setSelectedSpiderConfig}
                                    items={spiderConfigs}
                                    error={error}
                                    isInvalid={!!error}
                                    handleUpload={uploadSpiderConfig}
                                    extension={[".py"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.spider_config_file}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Yaml config file (generic files&nbsp;
                                    <Form.Check
                                        type="checkbox"
                                        checked={genericYamlConfigs}
                                        onChange={(e) => {
                                            setGenericYamlConfigs(e.target.checked);
                                            setProjectYamlConfigs(!e.target.checked);
                                        }}
                                    />):
                            </Col>
                            <Col md={8}>
                                <SelectOrUploadFile
                                    selected={selectedYamlConfig}
                                    setSelected={setSelectedYamlConfig}
                                    items={yamlConfigs}
                                    error={error}
                                    isInvalid={!!error}
                                    handleUpload={uploadYamlConfig}
                                    extension={[".yaml"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Field cannot be empty
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Template file:
                            </Col>
                            <Col md={8}>
                                <SelectOrUploadFile
                                    selected={selectedTemplateFile}
                                    setSelected={setSelectedTemplateFile}
                                    items={templateFiles}
                                    error={error}
                                    isInvalid={!!error}
                                    handleUpload={uploadTemplateFile}
                                    extension={[".xlsx", ".csv"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Field cannot be empty
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Spider kwargs:
                            </Col>
                            <Col className="ps-4 spider-name-md-5">
                                <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    className="rounded-pill"
                                    placeholder="{}"
                                    isInvalid={!spiderKwargs.startsWith("{") || !spiderKwargs.endsWith("}")}
                                    value={spiderKwargs}
                                    onChange={(e) => setSpiderKwargs(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Kwargs needs to be a valid JSON format
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Page type:
                            </Col>
                            <Col className="ps-4 spider-name-md-5">
                                <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    className="rounded-pill"
                                    placeholder="Page type"
                                    isInvalid={validated && pageType === ""}
                                    value={pageType}
                                    onChange={(e) => setPageType(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.name}
                                </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>
                        {userData.is_superuser &&
                                <div id="webscraper">
                                    <Row className="mx-3 my-3">
                                        <Col md={4} className="d-flex align-items-start">
                                            Webscraper:
                                        </Col>
                                        <Col className="text-start ps-4">
                                            <Button
                                                variant={isWebscraper ? "dark" : "secondary"}
                                                onClick={() => {
                                                    setIsWebscraper(!isWebscraper);
                                                    if (isWebscraper) {
                                                        setSelectedWebscraperAccount(null);
                                                        setSelectedSitemap({ id: null, name: "Select Sitemap" });
                                                    }
                                                }}
                                            >
                                                {isWebscraper ? "Webscraper enabled" : "Webscraper disabled"}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {isWebscraper && (
                                        <>
                                            <Row className="mx-3 my-3">
                                                <Col md={4} className="d-flex align-items-start">
                                                    Webscraper account:
                                                </Col>
                                                <Col md={8} className="ps-4 d-flex justify-content-start">
                                                    <Form.Group className="d-flex gap-3">
                                                        <Form.Check
                                                            type="radio"
                                                            id="apiKeyGeneral"
                                                            value="WEBSCRAPER_API_KEY_GENERAL"
                                                            checked={selectedWebscraperAccount === 'WEBSCRAPER_API_KEY_GENERAL'}
                                                            onChange={(e) => handleAccountChange(e.target.value)}
                                                            label="Account General"
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            id="apiKeyBayer"
                                                            value="WEBSCRAPER_API_KEY_BAYER"
                                                            checked={selectedWebscraperAccount === 'WEBSCRAPER_API_KEY_BAYER'}
                                                            onChange={(e) => handleAccountChange(e.target.value)}
                                                            label="Account Bayer"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 my-3">
                                                <Col className="d-flex align-items-center">
                                                    Webscraper sitemap:
                                                </Col>
                                                <Col sm={12} md={8}>
                                                    <SelectOrAddSitemap
                                                        selectedSitemap={selectedSitemap}
                                                        setSelectedSitemap={setSelectedSitemap}
                                                        sitemaps={sitemaps}
                                                        isLoading={isLoading}
                                                        isDownloading={isDownloading}
                                                        error={error}
                                                        handleSitemapCreate={handleSitemapCreate}
                                                        handleDownloadSitemap={handleDownloadSitemap}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>
                            }
                            {validationError && (
                                <Row className="my-1 text-start mx-5">
                                    <Col></Col>
                                    <Col sm={12} md={9} className='text-danger'>
                                        {validationError}
                                    </Col>
                                </Row>
                            )}
                        <hr></hr>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    className="mx-2"
                                    variant="pf23"
                                    onClick={(e) => {
                                        handleSpiderCreate(
                                            projectContext.id, newSpiderName, selectedCollection.id,
                                            selectedSpiderConfig.id, selectedYamlConfig.id, selectedTemplateFile.id, spiderKwargs, pageType, isWebscraper,
                                            selectedWebscraperAccount, selectedSitemap.id
                                        );
                                    }}
                                >
                                    Create
                                </Button>
                                <Button
                                    className="mx-2"
                                    variant="dark"
                                    onClick={(e) => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </section>
            }
            </Container>
        </>
    )
}


export default CreateSpiderPage;