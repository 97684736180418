import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import Navbar from './Navbar';
import AnonymousUser from './AnonymousUser';
import LoadingPage from '../pages/LoadingPage';


const PrivateRoute = ({ component: Component, title, userData, fetchingUser, ...rest }) => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        // console.log(fetchingUser)
        if (userData === undefined || Object.keys(userData).length === 0) {
            setErrorMessage(
                <>
                    <h1>You are not logged in.</h1>
                    {/* <h3>Please log-in or sign-up.</h3> */}
                </>
            );
            setAuthorized(false);
            return;
        }
        if (rest.requiresDEA && !userData.is_dea) {
            setIsLoggedIn(true);
            setErrorMessage(
                <>
                    <h1>Page requires DEA-level access.</h1>
                    <h3>If you are supposed to acces it, please contact our team.</h3>
                </>
            );
            setAuthorized(false);
            return;
        }
        if (rest.requiresBNG && !userData.is_bng) {
            setIsLoggedIn(true);
            setErrorMessage(
                <>
                    <h1>Page requires BNG-level access.</h1>
                    <h3>If you are BNG member, please contact our team.</h3>
                </>
            );
            setAuthorized(false);
            return;
        }
        setAuthorized(true);
    }, [userData])

    return (
        <>
            {fetchingUser && <LoadingPage/>}
            {!fetchingUser && authorized && <Component userData={userData} title={title}/>}
            {!fetchingUser && !authorized && (
                <Container fluid className='App bg-light-pf23 rounded-bottom min-vh-100'>
                    <Navbar userData={userData} goBackButton={true} loggingButtons={false}></Navbar>
                    <section>
                        <AnonymousUser isLoggedIn={isLoggedIn} errorMessage={errorMessage}/>
                    </section>
                </Container>
            )}

        </>
        // isLoggedIn ? (
        //         <Component isLoggedIn={isLoggedIn} title={title}/>
        //     ) : (
        //         // <Navigate to="/login" replace state={{ from: rest.location }} />
        //         <Container fluid className='App bg-light-pf23 rounded-bottom min-vh-100'>
        //             <Navbar goBackButton={true}></Navbar>
        //             <section>
        //                 <AnonymousUser/>
        //             </section>
        //         </Container>
        //     )
    );
};

export default PrivateRoute;