import { useEffect , useState } from "react"
import Pagination from 'react-bootstrap/Pagination';

// props.allItems -> items you want to split (ex. projects list, spiders list, tasks list)
// props.setItemsToDisplay -> items that are currently displayed
// props.maxDisplay -> max number of items displayed at one page
// props.activePage, props.setActivePage -> useState for current active page & changing current active page

const PaginationComponent = (props) => {
  const [paginationItems, setPaginationItems] = useState([]);

  useEffect(()=> {
    splitItems(props.activePage, props.setActivePage, props.allItems, props.setItemsToDisplay, props.maxDisplay)
  }, [props.activePage, props.allItems])
  
  function splitItems(activePage, setActivePage, allItems, setItemsToDisplay, maxDisplay) {
    let maxPage = Math.ceil(allItems.length / maxDisplay)
    let pagination = []
    
    if (maxPage == 1) {
      pagination.push([
        // <Pagination.First disabled />,
        // <Pagination.Prev disabled />,
        // <Pagination.Item key={1} active={true}>1</Pagination.Item>,
        // <Pagination.Next disabled />,
        // <Pagination.Last disabled />
      ])
    } 
    else {
      if (activePage == 1) {
        pagination.push([
          <Pagination.First key="first" disabled />,
          <Pagination.Prev key="last" disabled />,
        ])
      } else {
        pagination.push([
          <Pagination.First key="first" onClick={(e) => setActivePage(1)} />,
          <Pagination.Prev key="last" onClick={(e) => setActivePage(activePage - 1)} />
        ])
      }

      if (maxPage <= 5) {
        for (let number = 1; number <= maxPage; number++) {
          pagination.push(
            <Pagination.Item key={number} active={number === activePage} onClick={(e) => setActivePage(number)}>
              {number}
            </Pagination.Item>
          );
        }
      } else {
        let startPage = Math.max(1, activePage - 2);
        let endPage = Math.min(maxPage, activePage + 2);

        if (activePage > 3) {
          pagination.push(
            <Pagination.Ellipsis key={-1} onClick={(e) => setActivePage(activePage - 2)} />
          );
        }

        for (let number = startPage; number <= endPage; number++) {
          pagination.push(
            <Pagination.Item key={number} active={number === activePage} onClick={(e) => setActivePage(number)}>
              {number}
            </Pagination.Item>
          );
        }

        if (activePage < maxPage - 2) {
          pagination.push(
            <Pagination.Ellipsis key={-2} onClick={(e) => setActivePage(activePage + 2)} />
          );
        }
      }

      if (activePage == maxPage) {
        pagination.push([
          <Pagination.Next key="next" disabled />,
          <Pagination.Last key="last" disabled />
        ])
      } else {
        pagination.push([
          <Pagination.Next key="next" onClick={(e) => setActivePage(activePage + 1)} />,
          <Pagination.Last key="last" onClick={(e) => setActivePage(maxPage)} />
        ])
      }
    }

    setPaginationItems(pagination);
    setItemsToDisplay(allItems.filter(row => allItems.indexOf(row) < activePage * maxDisplay && allItems.indexOf(row) >= (activePage - 1) * maxDisplay))
  }

  return (
    <Pagination>
      {paginationItems}
    </Pagination>
  )
}

export default PaginationComponent;
