import { max } from "moment";
import { React, useEffect , useState } from "react"
import Pagination from 'react-bootstrap/Pagination';

// props.response -> response from API
// props.activePage, props.setActivePage -> useState for current active page & changing current active page

//NEW:
// props.pageLimit -> limit of items per page
// props.countAllResults -> total count of results
// props.activePage, props.setActivePage -> useState for current active page & changing current active page


const PaginationComponent = (props) => {
  const [paginationItems, setPaginationItems] = useState([]);
  let maxPage = Math.ceil(props.countAllResults / props.pageLimit);

  useEffect(() => {
    setPaginationItems(refreshPagination(props.activePage, props.setActivePage, maxPage));
  }, [props.activePage, props.countAllResults]);

  function refreshPagination(activePage, setActivePage, maxPage) {
    let pagination = [];

    if (maxPage <= 1) {
      return pagination;
    } else {
      if (activePage === 1) {
        pagination.push(
          <Pagination.First key="first" disabled />,
          <Pagination.Prev key="prev" disabled />
        );
      } else {
        pagination.push(
          <Pagination.First key="first" onClick={() => setActivePage(1)} />,
          <Pagination.Prev key="prev" onClick={() => setActivePage(activePage - 1)} />
        );
      }

      if (maxPage <= 5) {
        for (let number = 1; number <= maxPage; number++) {
          pagination.push(
            <Pagination.Item key={number} active={number === activePage} onClick={() => setActivePage(number)}>
              {number}
            </Pagination.Item>
          );
        }
      } else {
        let startPage = Math.max(1, activePage - 2);
        let endPage = Math.min(maxPage, activePage + 2);

        if (activePage > 3) {
          pagination.push(
            <Pagination.Ellipsis key="ellipsis-start" onClick={() => setActivePage(activePage - 2)} />
          );
        }

        for (let number = startPage; number <= endPage; number++) {
          pagination.push(
            <Pagination.Item key={number} active={number === activePage} onClick={() => setActivePage(number)}>
              {number}
            </Pagination.Item>
          );
        }

        if (activePage < maxPage - 2) {
          pagination.push(
            <Pagination.Ellipsis key="ellipsis-end" onClick={() => setActivePage(activePage + 2)} />
          );
        }
      }

      if (activePage === maxPage) {
        pagination.push(
          <Pagination.Next key="next" disabled />,
          <Pagination.Last key="last" disabled />
        );
      } else {
        pagination.push(
          <Pagination.Next key="next" onClick={() => setActivePage(activePage + 1)} />,
          <Pagination.Last key="last" onClick={() => setActivePage(maxPage)} />
        );
      }
      return pagination;
    }
  }

  return (
    <Pagination>
      {paginationItems}
    </Pagination>
  );
};

export default PaginationComponent;