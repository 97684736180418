import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ReactComponent as AddIcon } from '../assets/icon10_add.svg';
import { FaCloudDownloadAlt, FaSpinner } from "react-icons/fa";

import ItemsDropdown from "./ItemsDropdown";

const SelectOrAddSitemap = (props) => {
    const [addSitemap, setAddSitemap] = useState(false);
    const [newSitemapData, setNewSitemapData] = useState("");

    return (
        <Container>
            {!addSitemap && 
                <Row className="d-flex align-items-center">
                    <Col sm={12} md={7}>
                        <ItemsDropdown
                            selected={props.selectedSitemap}
                            setSelected={props.setSelectedSitemap}
                            items={props.sitemaps}
                            attr={"name"}
                            isLoading={props.isLoading}
                        />
                    </Col>
                    <Col sm={12} md={5} className="p-0">
                        <Button 
                            variant="none" 
                            size="md" 
                            onClick={(e) => setAddSitemap(true)}
                        >
                            {<AddIcon className='table-icon'/>}
                        </Button>
                        <Button 
                            variant="none" 
                            size="md" 
                            className='text-white bg-dark px-0 py-1 rounded'
                            onClick={(e) => props.handleDownloadSitemap()}
                            disabled={!props.selectedSitemap.id || props.isDownloading}
                        >
                        <a href="#" className='text-white download-icon p-1 detailview' onClick={(e) => e.preventDefault()}>
                            {props.isDownloading ? <FaSpinner className="fa-spin" /> : <FaCloudDownloadAlt />}
                        </a>
                        </Button>
                    </Col>
                </Row>
            }
            {addSitemap && 
                <Row className="d-flex align-items-center">
                    <Col sm={12} md={7}>
                        <Form>
                            <Form.Group controlId="formNewSitemapName">
                                <Form.Control 
                                    className="w-100"
                                    as="textarea"
                                    rows={4}
                                    placeholder="Sitemap name"
                                    value={newSitemapData}
                                    isInvalid={!!props.error}
                                    onChange={(e) => setNewSitemapData(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {typeof props.error === 'object' ? JSON.stringify(props.error) : props.error}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col sm={12} md={5}>
                    <Button 
                            variant="modal"
                            size="md"
                            className="w-50 rounded-pill"
                            onClick={(e) => {
                                if (newSitemapData) {
                                    props.handleSitemapCreate(newSitemapData)
                                        .then((doneAdding) => {
                                            if (doneAdding) {
                                                setAddSitemap(false);
                                            }
                                        });
                                }
                            }}
                            disabled={!newSitemapData || props.isLoading}
                        >
                            Add
                        </Button>
                        <Button 
                            variant="dark"
                            className="ms-2 rounded-pill"
                            size="md"
                            onClick={(e) => {
                                if(props.selectedSitemap === false){
                                    props.setSelectedSitemap({id: 0, name: "Select"})
                                };
                                setAddSitemap(false);
                            }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            }
        </Container>
    );
};

export default SelectOrAddSitemap;