import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ReactComponent as EditIcon } from '../../assets/icon08_edit.svg';
import { ReactComponent as DeleteIconOff } from '../../assets/icon09_delete_off.svg';
import RunIconWithWarning from '../RunIconWithWarning';
import DeleteIconWithWarning from '../DeleteIconWithWarning';
import PaginationComponent from '../ApiPagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

import { ReactComponent as TaskIcon } from '../../assets/icon06_tasks.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/icon05_details.svg';

const ChainedSpidersTable = (props) => {
    const navigate = useNavigate();

    const [initialRender, setInitialRender] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [dataTable, setDataTable] = useState([]);
    const [dataSize, setDataSize] = useState(0);
    const [requestData, setRequestData] = useState(true);

    const [activePage, setActivePage] = useState(1);
    const [sortColumn, setSortColumn] = useState("created_at");
    const [sortDirection, setSortDirection] = useState("-");
    const [filters, setFilters] = useState({
        name: '',
        created_at: '',
    });

    useEffect(() => {
        if (requestData) {
            fetchDataTable(setIsLoading, setDataTable, setDataSize);
        }
    }, [activePage, sortColumn, sortDirection, filters, requestData, props.refreshData]);

    function fetchDataTable(setIsLoading, setDataTable, setDataSize) {
        setIsLoading(true);
        props.client.getMyChainSpiders(activePage, sortColumn, sortDirection, filters).then(
            (response) => {
                setDataSize(response.data.count);
                setDataTable(response.data.results);
            }
        ).finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            setIsLoading(false);
        }
    }, [dataTable]);

    function handleSort(column) {
        setActivePage(1);
        if (sortColumn === column) {
            setSortDirection((prevSortDirection) =>
                prevSortDirection === "+" ? "-" : "+"
            );
        } else {
            setSortColumn(column);
            setSortDirection("+");
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setRequestData(true);
        }
    }

    function handleChainedSpiderRun(chainedSpiderId) {
        props.client.runOneOffChainedSpiderTask(chainedSpiderId)
            .then(() => fetchDataTable(setIsLoading, setDataTable, setDataSize))
            .catch((error) => console.log(error.response.statusText));
    }

    function handleEdit(chainedSpider) {
        navigate(`/chained-spider/${chainedSpider.id}`);
    }

    function handleDelete(chainedSpider) {
        props.client.deleteChainedSpider(chainedSpider.id)
            .then(() => fetchDataTable(setIsLoading, setDataTable, setDataSize))
            .catch((error) => console.log(error));
    }

    function handleSchedules(chainedSpider, setSpider, setShowSchedulesModal) {
        setSpider(chainedSpider);
        setShowSchedulesModal(true);
    }

    const handleChainedTaskResults = (chainedSpider) => {
        navigate(`/chained-spider/${chainedSpider.id}/task-results`, { state: { chainedSpiderId: chainedSpider.id, spiderContext: chainedSpider } });
    };

    return (
        <>
            <Table striped responsive bordered hover className='align-middle custom-table'>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("name")} className='sort-arrow'>
                            Chained Spider Name
                            <span>
                                {sortColumn === "name" && sortDirection === "+" && <FaSortUp />}
                                {sortColumn === "name" && sortDirection === "-" && <FaSortDown />}
                                {sortColumn !== "name" && <FaSort />}
                            </span>
                        </th>
                        <th onClick={() => handleSort("created_at")} className='sort-arrow'>
                            Created At
                            <span className="sort-arrow">
                                {sortColumn === "created_at" && sortDirection === "+" && <FaSortUp />}
                                {sortColumn === "created_at" && sortDirection === "-" && <FaSortDown />}
                                {sortColumn !== "created_at" && <FaSort />}
                            </span>
                        </th>
                        <th>Run</th>
                        <th>Edit</th>
                        <th>Schedules</th>
                        <th>Task results</th>
                        <th>Delete</th>
                    </tr>
                    <tr>
                        <th>
                            <Form.Control
                                type="text"
                                id="filter-name"
                                placeholder="Filter by name"
                                value={filters.name}
                                onBlur={() => { setActivePage(1); setRequestData(true); }}
                                onChange={(e) => { setRequestData(false); setFilters({ ...filters, name: e.target.value }); }}
                                onKeyDown={handleKeyDown}
                            />
                        </th>
                        <th>
                            <Form.Control
                                type="text"
                                id="filter-creation-date"
                                placeholder="Filter by creation date"
                                value={filters.created_at}
                                onBlur={() => { setActivePage(1); setRequestData(true); }}
                                onChange={(e) => { setRequestData(false); setFilters({ ...filters, created_at: e.target.value }); }}
                                onKeyDown={handleKeyDown}
                            />
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && <tr><td colSpan="7" className="text-center">Loading...</td></tr>}
                    {!isLoading && dataSize === 0 && <tr><td colSpan="7" className="text-center">No chained spiders found</td></tr>}
                    {!isLoading && dataTable.map((chainedSpider, index) => (
                        <tr key={index}>
                            <td>{chainedSpider.name}</td>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ hide: 250 }}
                                    overlay={
                                        <Tooltip>
                                            Created by {chainedSpider.user_email?.split('@')[0]}
                                        </Tooltip>
                                    }
                                >
                                    <a>{chainedSpider.created_at}</a>
                                </OverlayTrigger>
                            </td>
                            <td>
                                <RunIconWithWarning
                                    handleRun={() => handleChainedSpiderRun(chainedSpider.id)}
                                    warningBodyText={
                                        <a>You are about to start the <b>{chainedSpider.name}</b> now. Do you want to continue?</a>
                                    }
                                />
                            </td>
                            <td>
                                <Button
                                    size="md"
                                    variant='none'
                                    type="button"
                                    className='rounded-pill'
                                    onClick={() => handleEdit(chainedSpider)}
                                >
                                    {<EditIcon className='table-icon' />}
                                </Button>
                            </td>
                            <td>
                                <Button
                                    size="md"
                                    variant="none"
                                    type="button"
                                    onClick={(e) => {
                                        handleSchedules(chainedSpider, props.setSpider, props.setShowSchedulesModal)
                                    }}
                                >
                                    {<ScheduleIcon className='table-icon' />}
                                </Button>
                            </td> 
                            <td>
                                <Button
										size="md"
										variant="none"
                                        onClick={() => handleChainedTaskResults(chainedSpider)}
                                        type="button"
									>
										{<TaskIcon className='table-icon' />}
									</Button>
                            </td>
                            <td>
                                {props.userId === chainedSpider.user ?
                                    <DeleteIconWithWarning
                                        handleDelete={() => handleDelete(chainedSpider)}
                                        warningBodyText={
                                            <a>You are about to delete the <b>{chainedSpider.name}</b> chained spider. Do you want to continue?</a>
                                        }
                                    /> :
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ hide: 250 }}
                                        overlay={
                                            <Tooltip>
                                                You don't own this chained spider
                                            </Tooltip>
                                        }
                                    >
                                        <DeleteIconOff className='table-icon' />
                                    </OverlayTrigger>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Col>
                <PaginationComponent
                    pageLimit={props.maxPagination}
                    countAllResults={dataSize}
                    activePage={activePage}
                    setActivePage={setActivePage}
                />
            </Col>
        </>
    );
};

export default ChainedSpidersTable;